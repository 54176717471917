import { Icon } from "@ant-design/compatible";
import {
    ApartmentOutlined,
    ApiOutlined,
    AudioMutedOutlined,
    AudioOutlined,
    BookOutlined,
    CalendarOutlined,
    CheckSquareOutlined,
    CustomerServiceOutlined,
    DashboardOutlined,
    DollarCircleOutlined,
    DoubleRightOutlined,
    EditOutlined,
    ExperimentFilled,
    FacebookOutlined,
    FileTextOutlined,
    FireOutlined,
    LayoutOutlined,
    NodeExpandOutlined,
    NotificationFilled,
    PictureOutlined,
    PieChartOutlined,
    SearchOutlined,
    SettingFilled,
    SettingOutlined,
    SoundOutlined,
    StopOutlined,
    SwitcherOutlined,
    TagsOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import { Card } from "antd";
import React, { FunctionComponent, useMemo } from "react";
import { AppSummaryFragment } from "../../graphql/generated/config-graphql.g";

// NOTE: legacy fallback icons
const iconMap: { [key: string]: React.ReactNode } = {
    flask: ExperimentFilled,
    cog: SettingFilled,
    bullhorn: NotificationFilled,
    edit: EditOutlined,
    "file-photo-o": PictureOutlined,
    "check-square": CheckSquareOutlined,
    book: BookOutlined,
    "sort-amount-asc": PieChartOutlined,
    "file-text-o": FileTextOutlined,
    rss: NodeExpandOutlined,
    "fire-extinguisher": AudioMutedOutlined, // idk
    sitemap: ApartmentOutlined,
    "volume-up": SoundOutlined,
    fire: FireOutlined,
    "calendar-o": CalendarOutlined,
    "file-picture-o": PictureOutlined,
    wrench: SettingOutlined,
    forward: DoubleRightOutlined,
    bolt: ThunderboltOutlined,
    undo: UndoOutlined,
    "space-shuttle": DashboardOutlined, // idk
    "facebook-square": FacebookOutlined,
    tags: TagsOutlined,
    users: TeamOutlined,

    dollar: StopOutlined,
    calendar: CalendarOutlined,
    microphone: AudioOutlined,
    "picture-o": SwitcherOutlined,
    "toggle-right": CustomerServiceOutlined,
    search: SearchOutlined,
    building: StopOutlined,
    "building-o": LayoutOutlined,
    money: DollarCircleOutlined,
    recycle: ApiOutlined,
};

export const AppItem = ({ app }: { app: AppSummaryFragment }) => {
    const icon = useMemo(
        () =>
            iconMap[app.icon] ? (
                React.createElement(iconMap[app.icon] as FunctionComponent<{ className: string }>, {
                    className: "card-avatar",
                })
            ) : (
                <Icon type={app.icon} theme="outlined" className="card-avatar"></Icon>
            ),
        [app],
    );
    return (
        <a href={app.link} target="_blank" rel="noreferrer" className="app-item" title={app.name}>
            <Card hoverable={true} className="app-card">
                <Card.Meta avatar={icon} title={app.name} description={app.description} />
            </Card>
        </a>
    );
};
