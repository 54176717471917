import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { NormalizedNotification, notificationsApi } from "../../api/notifications";
import { AuthContext } from "../../contexts/auth";
import "./AppNotifications.style.scss";

export const AppNotification = () => {
    const {user} = useContext(AuthContext);
    const [notifications, setNotifications] = useState<NormalizedNotification[]>([]);
    useEffect(() => {
        if (!notifications.length) {
            notificationsApi.loadNotifications().then(setNotifications);
        }
        const notificationInterval = setInterval(async () => {
            if (!notifications.length) {
                setNotifications(await notificationsApi.loadNotifications());
            }
        }, 5 * 60 * 1000); // 5 Minutes

        return () => clearInterval(notificationInterval);
    }, [notifications.length]);

    const [notificationIndex, updateNotificationIndex] = useState(0);
    const notification = useMemo(() => notifications[notificationIndex], [notificationIndex, notifications]);

    const primaryButtonAction = useCallback(async () => {
        if (notificationIndex === notifications.length - 1) {
            updateNotificationIndex(-1); // Immediately dismisses the modal
            await notificationsApi.dismiss(notifications);
            setNotifications([]);
        } else {
            updateNotificationIndex(notificationIndex + 1);
        }
    }, [notificationIndex, notifications, setNotifications]);

    const leftButton = useMemo(
        () =>
            notificationIndex > 0 ? (
                <>
                    <ArrowLeftOutlined /> Previous
                </>
            ) : null,
        [notificationIndex],
    );

    const rightButton = useMemo(
        () =>
            notificationIndex < notifications.length - 1 ? (
                <>
                    More <ArrowRightOutlined />
                </>
            ) : (
                <>
                    <CheckOutlined /> Acknowledge
                </>
            ),
        [notificationIndex, notifications.length],
    );

    if (!notifications.length || !notification) {
        return null;
    }

    return (
        <Modal
            visible={!!notifications.length && !!user}
            cancelButtonProps={{ style: { display: !!leftButton ? "initial" : "none" } }}
            cancelText={leftButton}
            onCancel={() => updateNotificationIndex(notificationIndex - 1)}
            okText={rightButton}
            onOk={primaryButtonAction}
            closable={false}
        >
            <div className="notification-content">
                <InfoCircleOutlined />
                <div>
                    <h3>{notification.title}</h3>
                    <div>
                        <p dangerouslySetInnerHTML={{ __html: notification.description }} />
                    </div>
                    {notification.link ? (
                        <p>
                            <a href={notification.link} target="_blank" rel="noreferrer">
                                Click here for more information
                            </a>
                        </p>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};
