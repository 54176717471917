import { AuthClient } from "@ihr-radioedit/service-auth";

// Doesn't need to extend ApiBase since the client handles its own state
class Auth {
    private client = AuthClient.auto();

    async getToken() {
        try {
            return await this.client.getMyPrincipal();
        } catch (err) {
            if (err.code === "401") {
                // This means we're not logged in
                console.warn("Auth Status check indicates no session");
                return null;
            } else {
                console.error(err);
                return null;
            }
        }
    }
}

export const authApi = new Auth();
