import { PrincipalObj } from "@ihr-radioedit/service-auth";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { authApi } from "../api/auth";

export const AuthContext = createContext<ReturnType<typeof useAuthInitialState>>({} as any);

export const useAuthInitialState = () => {
    const [user, setUser] = useState<PrincipalObj | null>(null);
    useEffect(() => {
        authApi.getToken().then(setUser);
    }, []);

    const login = useCallback((user: PrincipalObj) => {
        setUser(user);
    }, []);

    const logout = useCallback(() => {
        setUser(null);
    }, []);

    return { user, login, logout };
};

export const useAuthContext = () => useContext(AuthContext);
