import { createContext, useContext, useEffect, useState } from "react";
import { Apps, appsApi } from "../api/apps";

export const AppsContext = createContext<ReturnType<typeof useAppsInitialState>>({} as any);

export const useAppsInitialState = () => {
    const [apps, setApps] = useState<Apps | null>(null);
    useEffect(() => {
        appsApi
            .load()
            .then(setApps)
            .finally(() => appsApi.getApps().then((apps) => apps && setApps(apps)));
    }, []);
    useEffect(() => {
        appsApi.set(apps);
    }, [apps]);

    return { apps };
};

export const useAppsContext = () => useContext(AppsContext);
