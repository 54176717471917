import { Layout } from "antd";
import "./Header.style.scss";

interface HeaderProps {
    appName: string;
}

export const Header = ({ appName }: HeaderProps) => (
    <Layout.Header className="app-header">
        <h3>{appName}</h3>
    </Layout.Header>
);
