import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { sortApi } from "../api/sort";

export enum SortTypes {
    fav = "fav",
    alpha = "alpha",
}

export const SortContext = createContext<ReturnType<typeof useSortInitialState>>({} as any);

export const useSortInitialState = () => {
    const [sort, setSort] = useState(SortTypes.fav);
    const [nameSearch, setNameSearch] = useState("");

    useEffect(() => {
        sortApi.load().then((sort) => (sort ? setSort(sort) : setSort(SortTypes.fav)));
    }, []);

    useEffect(() => {
        if (sort) {
            sortApi.set(sort);
        }
    }, [sort]);

    const sortByAlphabetical = useCallback(() => {
        setSort(SortTypes.alpha);
    }, [setSort]);

    return { sort, sortByAlphabetical, nameSearch, setNameSearch };
};

export const useSortContext = () => useContext(SortContext);
