import { environ } from "@ihr-radioedit/sdk-utils";
import { Layout } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useCallback, useState } from "react";
import { Route } from "react-router-dom";
import { useAuthContext } from "../contexts/auth";
import { useSideEffects } from "../side-effects/side-effects";
import { AppList } from "./app-list/AppList.component";
import { Filters } from "./app-list/Filters.component";
import { AppNotification } from "./app-notifications/AppNotifications.component";
import "./App.style.scss";
import { Header } from "./header/Header.component";
import { Help } from "./Help.component";
import { Logo } from "./logo/logo.component";
import { BottomMenu } from "./menu/BottomMenu.component";
import { NavMenu } from "./menu/NavMenu.component";
import { Unauthenticated } from "./unauthenticated/Unauthenticated.component";

const { Sider, Content } = Layout;

export const App = () => {
    const minimumWidth = 760;
    const width = typeof window !== "undefined" ? window.innerWidth : minimumWidth + 1;
    const [isCollapsed, setCollapsed] = useState(width <= minimumWidth);
    const updateCollapsed = useCallback(() => setCollapsed(!isCollapsed), [isCollapsed]);
    const { user } = useAuthContext();
    useSideEffects();

    return (
        <Layout className="app">
            <Sider collapsible collapsed={isCollapsed} width="300" collapsedWidth="48" trigger={null}>
                <div className="sidenav">
                    <Logo isCollapsed={isCollapsed} isProd={environ.get("APP_ENV", "stage") === "prod"} />

                    <div className="menu">
                        <NavMenu isCollapsed={isCollapsed} />
                    </div>

                    <div className="bottom-nav">
                        <BottomMenu isCollapsed={isCollapsed} updateCollapsed={updateCollapsed} />
                    </div>
                </div>
            </Sider>

            <Content className={"app-content" + (isCollapsed ? " collapsed" : "")}>
                <Header appName="My Apps" />
                <AppNotification />
                <div className="apps-view">
                    <Route path="/help" exact={true}>
                        <Help />
                    </Route>
                    <Route path="/" exact={true}>
                        {user ? <Filters /> : null}
                        {user ? <AppList /> : <Unauthenticated />}
                    </Route>
                    <Footer className="app-footer">
                        RadioEdit <span className="app-name">Publishing Tools</span> ©{new Date().getFullYear()}
                        {environ.application?.version ? <> - Version {environ.application?.version}</> : null}
                        {environ.application?.build ? <> - Build {environ.application?.build}</> : null}
                    </Footer>
                </div>
            </Content>
        </Layout>
    );
};
