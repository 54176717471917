import "./Help.style.scss";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

const email = "digitalsupport@iheartmedia.com";

export const Help = () => {
    return (
        <div className="help-info">
            <section>
                <h3>Contact Support:</h3>
                <ul>
                    <li>
                        <a href={`mailto:${email}`}>
                            <MailOutlined />
                            <span>{email}</span>
                        </a>
                    </li>
                    <li>
                        <a href="tel:+18668062515">
                            <PhoneOutlined />
                            <span>(866) 806-2515 option 2</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://digitalsupport.iheart.com" target="_blank" rel="noreferrer">
                            Documentation
                        </a>
                    </li>
                </ul>
            </section>
        </div>
    );
};
