import { localStorage, LocalStorageInstanceTypes } from "../util/localforage";

export class ApiBase<T> {
    private instance: LocalForage;
    constructor(private key: LocalStorageInstanceTypes) {
        this.instance = localStorage.getInstance(LocalStorageInstanceTypes[key]);
    }

    load = () => this.instance.getItem<T | null>(this.key);
    set = (item: T | null) => this.instance.setItem(this.key, item);
    clear = () => this.instance.setItem<T | null>(this.key, null);
}
