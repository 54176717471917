import { connectionRequestFactory } from "@ihr-radioedit/gql-client";
import { ConnectionConfig } from "@ihr-radioedit/sdk-network";
import { environ } from "@ihr-radioedit/sdk-utils";
import { ImplicitAuthenticationConnection } from "@ihr-radioedit/service-auth";
import { getSdk as getConfigSdk } from "./generated/config-graphql.g";
import { getSdk as getWebapiSdk } from "./generated/webapi-graphql.g";

export const configSdk = (() => {
    const options: ConnectionConfig = {
        baseURL: `https://${environ.dep("config")}/graphql`,
        timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
    };
    const client = new ImplicitAuthenticationConnection(options);
    const requestFactory = connectionRequestFactory(client);
    return getConfigSdk(requestFactory);
})();

export const webapiSdk = (() => {
    const options: ConnectionConfig = {
        baseURL: `https://${environ.dep("webapi")}/graphql`,
        timeout: parseInt(environ.get("REQUEST_TIMEOUT", "0"), 10),
    };
    const client = new ImplicitAuthenticationConnection(options);
    const requestFactory = connectionRequestFactory(client);
    return getWebapiSdk(requestFactory);
})();
