import localForage from "localforage";

export enum LocalStorageInstanceTypes {
    "sort" = "sort",
    "list" = "list",
    "apps" = "apps",
    "notifications" = "notifications",
}

type LocalStorageMap<T> = {
    [s in LocalStorageInstanceTypes]?: T;
};

const VERSIONS: LocalStorageMap<number> = {
    [LocalStorageInstanceTypes["sort"]]: 1,
    [LocalStorageInstanceTypes["list"]]: 1,
    [LocalStorageInstanceTypes["apps"]]: 1,
    [LocalStorageInstanceTypes["notifications"]]: 1,
};

class LocalStorageService {
    private instances: LocalStorageMap<LocalForage> = {};

    constructor() {
        Object.keys(LocalStorageInstanceTypes).forEach((type) => {
            this.instances[type as LocalStorageInstanceTypes] = localForage.createInstance({
                name: `shell-${type}-${VERSIONS[type as LocalStorageInstanceTypes]}`,
            });
        });
        this.reapOldDocuments();
    }

    getInstance(key: LocalStorageInstanceTypes) {
        return this.instances[key]!; // These are set in the constructor so we know they exist.
    }

    private reapOldDocuments() {
        Object.keys(VERSIONS).forEach((appName) => {
            const currentVersion = VERSIONS[appName as LocalStorageInstanceTypes];
            if (currentVersion && currentVersion > 1) {
                for (let i = 1; i < currentVersion; i++) {
                    localForage.dropInstance({ name: `${appName}-${i}` }).catch(() => null);
                }
            }
        });
    }
}

export const localStorage = new LocalStorageService();
