// With HKT we could eliminate dependencies on our client project (https://github.com/microsoft/TypeScript/issues/1213)
import { GraphQLRequestFactory } from "@ihr-radioedit/gql-client";
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  /** References an image from mediaserver through RadioEdit's asset manager */
  ImageRef: any;
};

export type AdBreakpoint = {
  breakpoint: Scalars['Float'];
  sizes: Array<AdSize>;
};

export type AdConfig = {
  positions: Array<AdPosition>;
};

export type AdParams = {
  keywords: Scalars['String'];
  topics: Scalars['String'];
};

export type AdPosition = {
  ad_split: Scalars['Float'];
  force_load: Scalars['Boolean'];
  position_id: Scalars['String'];
  breakpoints: Array<AdBreakpoint>;
};

export type AdSize = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type AdswizzRef = {
  publisher_id?: Maybe<Scalars['String']>;
  enableAdswizzTargeting?: Maybe<Scalars['Boolean']>;
  adswizzHost?: Maybe<Scalars['String']>;
};

export type AdswizzZonesRef = {
  audioFillZone?: Maybe<Scalars['String']>;
  audioExchangeZone?: Maybe<Scalars['String']>;
  audioZone?: Maybe<Scalars['String']>;
  displayZone?: Maybe<Scalars['String']>;
  optimizedAudioFillZone?: Maybe<Scalars['String']>;
};

export type AmpFunctions = {
  podcast?: Maybe<Podcast>;
};


export type AmpFunctionsPodcastArgs = {
  id: Scalars['Int'];
};

export type AmpLiveMeta = {
  topArtists: LiveMetaTopArtists;
  recentlyPlayed: LiveMetaRecentlyPlayed;
  topTracks: LiveMetaTopTracks;
  currentTrack?: Maybe<PnpTrack>;
  currentlyPlaying: LiveMetaCurrentlyPlaying;
};


export type AmpLiveMetaTopArtistsArgs = {
  paging?: Maybe<AmpPageInput>;
};


export type AmpLiveMetaRecentlyPlayedArgs = {
  paging?: Maybe<AmpPageInput>;
};


export type AmpLiveMetaTopTracksArgs = {
  paging?: Maybe<AmpPageInput>;
};


export type AmpLiveMetaCurrentlyPlayingArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type AmpPageInfo = {
  nextCursor?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export type AmpPageInput = {
  take?: Maybe<Scalars['Int']>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type AptivadaApp = {
  appType: Scalars['String'];
  appId: Scalars['String'];
  account: Scalars['String'];
  parentAppId: Scalars['String'];
  title: Scalars['String'];
  shareTitle: Scalars['String'];
  tags: Scalars['String'];
  gridStatus: Scalars['String'];
  gridRedirect: Scalars['String'];
  pageUrl: Scalars['String'];
  primaryImage: Scalars['String'];
  mobileImage: Scalars['String'];
  gridImage: Scalars['String'];
  prize: Scalars['String'];
  sort: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  scheduleStatus: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  active?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  displayImage?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  facebookShareImage?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  shareLocation?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type AptivadaFunctions = {
  apps?: Maybe<Array<AptivadaApp>>;
  app?: Maybe<AptivadaApp>;
  keyword?: Maybe<AptivadaKeywordApp>;
};


export type AptivadaFunctionsAppArgs = {
  appId: Scalars['String'];
};


export type AptivadaFunctionsKeywordArgs = {
  appId: Scalars['String'];
};

export type AptivadaKeywordApp = {
  appType: Scalars['String'];
  appId: Scalars['String'];
  title: Scalars['String'];
  currentKeyword: CurrentKeyword;
  submittableKeyword?: Maybe<CurrentKeyword>;
};

export type Artist = {
  id: Scalars['Int'];
  artistName: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  genreId?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  facUpdate: Scalars['Float'];
  roviImages?: Maybe<Array<Scalars['String']>>;
};

export type ArtistFunctions = {
  /** Lookup Leads cards targeted to the specified artist. */
  leads: Array<Card>;
  /** Lookup articles that related to the artist specified. */
  content: Array<ContentRecord>;
};


export type ArtistFunctionsLeadsArgs = {
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type ArtistFunctionsContentArgs = {
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type BaseConfig = {
  /** pages: [Page]! */
  pages: Scalars['JSON'];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
};

export type BaseIndex = {
  brand_id: Scalars['String'];
  configs: Array<BaseIndexConfigPointer>;
  default: Scalars['String'];
  description: Scalars['String'];
  facets: Array<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['ImageRef']>;
  legacy_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  own_brand: Scalars['String'];
  slug: Scalars['String'];
  tags: Array<Maybe<Scalars['String']>>;
};

export type BaseIndexConfigPointer = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BroadcastFacility = {
  broadcast_frequency: Scalars['String'];
  broadcast_band: Scalars['String'];
  facility_id: Scalars['String'];
  broadcast_call_letters: Scalars['String'];
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CacheResponse = {
  status: Scalars['Float'];
};

export type CalendarContentRef = {
  author: Scalars['String'];
  id: Scalars['String'];
  img: Scalars['String'];
  publish_date?: Maybe<Scalars['Float']>;
  publish_origin: Scalars['String'];
  summary: Scalars['String'];
  title: Scalars['String'];
  slug: Scalars['String'];
  record?: Maybe<ContentRecord>;
};

export type CalendarEvent = {
  endDateTime?: Maybe<Scalars['Float']>;
  eventId: Scalars['String'];
  origins: Array<Scalars['String']>;
  startDateTime: Scalars['Float'];
  tags: Array<Scalars['String']>;
  content: CalendarContentRef;
  venueName?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type CalendarFunctions = {
  event?: Maybe<CalendarEvent>;
  occurrence?: Maybe<CalendarEvent>;
};


export type CalendarFunctionsEventArgs = {
  eventId?: Maybe<Scalars['String']>;
};


export type CalendarFunctionsOccurrenceArgs = {
  occurrenceId?: Maybe<Scalars['String']>;
};

export type Card = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  link?: Maybe<CardLink>;
  background_color?: Maybe<Scalars['String']>;
  img_uri?: Maybe<Scalars['String']>;
  catalog?: Maybe<Catalog>;
  img_meta?: Maybe<CardImageMeta>;
  targets?: Maybe<Array<Subscription>>;
  use_catalog_image?: Maybe<Scalars['Boolean']>;
  locales?: Maybe<Array<Scalars['String']>>;
  range: CardRange;
};

export type CardImageMeta = {
  base_id?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
};

export type CardLink = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  urls?: Maybe<CardLinkUrls>;
};

export type CardLinkUrls = {
  device?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
};

export type CardPayload = {
  position: Scalars['Float'];
  priority: Scalars['Float'];
  range?: Maybe<CardPayloadRange>;
  targets?: Maybe<Array<Subscription>>;
  variants: Array<Card>;
};

export type CardPayloadRange = {
  begin?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export type CardRange = {
  begin?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export type CardRecord = {
  ref_id: Scalars['ID'];
  slug: Scalars['String'];
  summary: PublishSummary;
  payload: CardPayload;
  subscription: Array<Subscription>;
  ad_params: AdParams;
  pub_start: Scalars['Float'];
  pub_until?: Maybe<Scalars['Float']>;
};

export type Catalog = {
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogPointerRef = {
  id: Scalars['ID'];
  kind: Scalars['String'];
  summary?: Maybe<CatalogServiceSummary>;
};

export type CatalogRef = {
  id: Scalars['String'];
  type: Scalars['String'];
  name: Scalars['String'];
};

export type CatalogServiceSummary = {
  name: Scalars['String'];
  img: Scalars['String'];
};

export type ChartFunctions = {
  charts: ChartsCharts;
  lists: ChartsLists;
  chartDates: ChartsChartDates;
};

export type ChartsChart = {
  brand: SiteRef;
  created: DateRef;
  createdBy: Scalars['String'];
  published: DateRef;
  slug: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  canonicalUrl?: Maybe<Scalars['String']>;
  lists: ChartsListResults;
  dates?: Maybe<ChartsChartDatesList>;
};


export type ChartsChartListsArgs = {
  filters?: Maybe<ChartsListsFilters>;
};

export type ChartsChartDates = {
  byId?: Maybe<ChartsChartDatesList>;
  bySlug?: Maybe<ChartsChartDatesList>;
};


export type ChartsChartDatesByIdArgs = {
  id: Scalars['String'];
};


export type ChartsChartDatesBySlugArgs = {
  slug: Scalars['String'];
};

export type ChartsChartDatesList = {
  years: Array<Scalars['String']>;
  months: Array<ChartsChartYearMonth>;
};

export type ChartsChartListSong = {
  artist?: Maybe<Scalars['String']>;
  catalogRef?: Maybe<CatalogPointerRef>;
  artistRef?: Maybe<CatalogPointerRef>;
  delta: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  peak: Scalars['Int'];
  pos: Scalars['Int'];
  timeOnChart: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ChartsCharts = {
  byId?: Maybe<ChartsChart>;
  bySlug?: Maybe<ChartsChart>;
  filter: ChartsChartsResults;
};


export type ChartsChartsByIdArgs = {
  id: Scalars['String'];
};


export type ChartsChartsBySlugArgs = {
  slug: Scalars['String'];
};


export type ChartsChartsFilterArgs = {
  filters?: Maybe<ChartsChartsFilters>;
};

export type ChartsChartsFilters = {
  searchTerm?: Maybe<Scalars['String']>;
  editor?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type ChartsChartsResults = ChartsPagedResult & {
  items: Array<ChartsChart>;
  count: Scalars['Int'];
  pageInfo: ChartsPageInfo;
};

export type ChartsChartYearMonth = {
  year: Scalars['Int'];
  months: Array<Scalars['Int']>;
};

export type ChartsList = {
  id: Scalars['String'];
  chart?: Maybe<ChartsChart>;
  title: Scalars['String'];
  chartDate?: Maybe<DateRef>;
  created: DateRef;
  createdBy: Scalars['String'];
  published: DateRef;
  publishedEnd?: Maybe<DateRef>;
  slug: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  songs: Array<ChartsChartListSong>;
  previousList?: Maybe<ChartsList>;
  nextList?: Maybe<ChartsList>;
  topItems?: Maybe<Scalars['String']>;
};

export type ChartsListResults = ChartsPagedResult & {
  items: Array<ChartsList>;
  count: Scalars['Int'];
  pageInfo: ChartsPageInfo;
};

export type ChartsLists = {
  byId?: Maybe<ChartsList>;
  bySlug?: Maybe<ChartsList>;
  filter: ChartsListResults;
};


export type ChartsListsByIdArgs = {
  id: Scalars['String'];
};


export type ChartsListsBySlugArgs = {
  slug: Scalars['String'];
};


export type ChartsListsFilterArgs = {
  chartId?: Maybe<Scalars['String']>;
  chartSlug?: Maybe<Scalars['String']>;
  filters?: Maybe<ChartsListsFilters>;
};

export type ChartsListsFilters = {
  chartDate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Float']>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type ChartsPagedResult = {
  count: Scalars['Int'];
  pageInfo: ChartsPageInfo;
};

export type ChartsPagedResults = ChartsPagedResult & {
  count: Scalars['Int'];
  pageInfo: ChartsPageInfo;
};

export type ChartsPageInfo = {
  nextCursor?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export type Collection = {
  id: Scalars['String'];
  userId: Scalars['String'];
  name: Scalars['String'];
  duration: Scalars['Float'];
  dateCreated: Scalars['Float'];
  lastUpdated: Scalars['Float'];
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  reportingKey: Scalars['String'];
  slug: Scalars['String'];
  urls: Array<CollectionUrls>;
  writeable: Scalars['Boolean'];
  deletable: Scalars['Boolean'];
  renameable: Scalars['Boolean'];
  followable: Scalars['Boolean'];
  followed: Scalars['Boolean'];
  curated: Scalars['Boolean'];
  shareable: Scalars['Boolean'];
  allowed?: Maybe<Scalars['Int']>;
  prepopulated?: Maybe<Scalars['Int']>;
  tracks: Array<CollectionItem>;
  premium?: Maybe<Scalars['Boolean']>;
  backfillTracks: Array<Scalars['Int']>;
};

export type CollectionItem = {
  id: Scalars['String'];
  trackId: Scalars['Int'];
  track?: Maybe<PublicTrack>;
};

export type CollectionUrls = {
  web: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  play: Scalars['String'];
  goto?: Maybe<Scalars['String']>;
};

export type CombinedPnpTrackHistory = TrackHistory & {
  artistId: Scalars['Int'];
  albumId: Scalars['Int'];
  trackId: Scalars['Int'];
  title: Scalars['String'];
  trackDuration?: Maybe<Scalars['Int']>;
  imagePath?: Maybe<Scalars['String']>;
  explicitLyrics?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  lyricsId?: Maybe<Scalars['Int']>;
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  trackDurationInSec?: Maybe<Scalars['Int']>;
  dataSource?: Maybe<Scalars['String']>;
  isCurrentlyPlayingTrack: Scalars['Boolean'];
  artist?: Maybe<Artist>;
  artistName: Scalars['String'];
  albumName: Scalars['String'];
};

export type ConfigData = {
  /**
   * _id: String
   * id: String
   */
  index: BaseIndex;
  config: BaseConfig;
};

export type ContentFunctions = {
  item?: Maybe<ContentRecord>;
  /**
   * Lookup the full contents of a piece of content by its id
   * DEPRECATED
   */
  id?: Maybe<ContentRecord>;
  /**
   * Lookup the full contents of a piece of content by its slug
   * DEPRECATED
   */
  slug?: Maybe<ContentRecord>;
  /**
   * Accepts a complex data structure of type `PublishingQueryInput`. This allows you to
   * do advanced searches against the content publishing database.
   */
  query: Array<ContentRecord>;
};


export type ContentFunctionsItemArgs = {
  select: ContentQuery;
};


export type ContentFunctionsIdArgs = {
  id: Scalars['String'];
};


export type ContentFunctionsSlugArgs = {
  slug: Scalars['String'];
};


export type ContentFunctionsQueryArgs = {
  query: QueryInput;
};

export type ContentPayload = {
  include_recommendations: Scalars['Boolean'];
  fb_allow_comments: Scalars['Boolean'];
  amp_enabled?: Maybe<Scalars['Boolean']>;
  seo_title?: Maybe<Scalars['String']>;
  social_title?: Maybe<Scalars['String']>;
  publish_origin: Scalars['String'];
  keywords: Array<Scalars['String']>;
  blocks: Array<Scalars['JSON']>;
  canonical_url: Scalars['String'];
  cuser: Scalars['String'];
  external_url?: Maybe<Scalars['String']>;
  is_sponsored: Scalars['Boolean'];
  simplereach_enabled?: Maybe<Scalars['Boolean']>;
  permalink?: Maybe<Scalars['String']>;
  feed_permalink?: Maybe<Scalars['String']>;
  feed_vendor?: Maybe<Scalars['String']>;
  show_updated_timestamp?: Maybe<Scalars['Boolean']>;
  feed_content_id?: Maybe<Scalars['String']>;
};

export type ContentQuery = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ContentRecord = {
  ref_id: Scalars['ID'];
  slug: Scalars['String'];
  summary: PublishSummary;
  payload: ContentPayload;
  subscription: Array<Subscription>;
  ad_params: AdParams;
  pub_start: Scalars['Float'];
  pub_until?: Maybe<Scalars['Float']>;
  pub_changed?: Maybe<Scalars['Float']>;
  eyebrow?: Maybe<Eyebrow>;
};

export type CurrentKeyword = {
  previous: KeywordDetails;
  current: KeywordDetails;
  next: KeywordDetails;
};

export type CustomLink = {
  title: Scalars['String'];
  link: Scalars['String'];
  navigationIcon: Scalars['String'];
};

export type DatasourceFeed = {
  entries?: Maybe<Array<DatasourceFeedEntry>>;
  id: Scalars['String'];
  repeat_start_idx?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type DatasourceFeedEntry = {
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  ref_id: Scalars['String'];
  type: Scalars['String'];
};

export type DatasourceFeedItem = {
  ref_id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
};

export type DatasourcePubsubQuery = {
  type?: Maybe<Scalars['String']>;
  targets?: Maybe<Array<DatasourcePubsubTarget>>;
};

export type DatasourcePubsubTarget = {
  what?: Maybe<Array<Maybe<Scalars['String']>>>;
  where?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DatasourceQuery = {
  id: Scalars['String'];
  title: Scalars['String'];
  pubsub: DatasourcePubsubQuery;
};

export type DateRef = {
  timestampMs: Scalars['Float'];
  timestamp: Scalars['Float'];
  iso8601: Scalars['String'];
};

export type Eyebrow = {
  topic?: Maybe<Scalars['String']>;
  topic_name?: Maybe<Scalars['String']>;
};

export type FeedRef = {
  widget?: Maybe<Scalars['Boolean']>;
  sponsored?: Maybe<Scalars['Boolean']>;
  childOriented?: Maybe<Scalars['Boolean']>;
  regGate?: Maybe<Scalars['Boolean']>;
  feed?: Maybe<Scalars['String']>;
  enableTritonTracking?: Maybe<Scalars['Boolean']>;
  site_id?: Maybe<Scalars['Float']>;
};

export type Genre = {
  id: Scalars['Int'];
  brand?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  genreGroup: Scalars['String'];
  genreName: Scalars['String'];
  metadata: GenreMeta;
  sortOrder?: Maybe<Scalars['Int']>;
  site?: Maybe<SiteFunctions>;
};

export type GenreFunctions = {
  /** Lookup Leads cards targeted to the specified genre. */
  leads: Array<Card>;
  /** Lookup articles that related to the genre specified. */
  content: Array<ContentRecord>;
  /** Lookup genre object from streams based on genre id */
  genre?: Maybe<Genre>;
};


export type GenreFunctionsLeadsArgs = {
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
};


export type GenreFunctionsContentArgs = {
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GenreMeta = {
  icons?: Maybe<Icons>;
};

export type GenreRef = {
  id: Scalars['Float'];
  name: Scalars['String'];
  country: Scalars['String'];
  sortIndex?: Maybe<Scalars['Float']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type Icon = {
  url: Scalars['String'];
  genreName: Scalars['String'];
  display: Scalars['Boolean'];
  locales: Array<Maybe<Scalars['String']>>;
};

export type Icons = {
  primary?: Maybe<Array<Maybe<Icon>>>;
  secondary?: Maybe<Array<Maybe<Icon>>>;
};



export type KeywordDetails = {
  sort?: Maybe<Scalars['String']>;
  timeStamp?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isStarted?: Maybe<Scalars['Boolean']>;
  isSubmittable?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  countdownKeywordId?: Maybe<Scalars['String']>;
  prize?: Maybe<Scalars['JSON']>;
};

export type LiveMetaCurrentlyPlaying = {
  count: Scalars['Int'];
  pageInfo: AmpPageInfo;
  tracks: Array<CombinedPnpTrackHistory>;
};

export type LiveMetaRecentlyPlayed = {
  count: Scalars['Int'];
  pageInfo: AmpPageInfo;
  tracks: Array<PnpTrackHistory>;
};

export type LiveMetaTopArtists = {
  count: Scalars['Int'];
  pageInfo: AmpPageInfo;
  artists: Array<TopArtist>;
};

export type LiveMetaTopTracks = {
  count: Scalars['Int'];
  pageInfo: AmpPageInfo;
  tracks: Array<TopTrack>;
};

export type MarketRef = {
  id: Scalars['Float'];
  name: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  stateId: Scalars['Float'];
  country: Scalars['String'];
  countryId: Scalars['Float'];
  sortIndex: Scalars['Float'];
  cityId: Scalars['Float'];
  isOrigin: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
};

export type MediaAsset = {
  bucket: Scalars['String'];
  resourceId: Scalars['String'];
  metadata?: Maybe<MediaAssetMetadata>;
  href: Scalars['String'];
};


export type MediaAssetHrefArgs = {
  cdnUrl?: Maybe<Scalars['String']>;
};

export type MediaAssetMetadata = {
  mimetype?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  length: Scalars['Int'];
  keys: Array<Scalars['String']>;
  signature: Scalars['String'];
  properties: Scalars['JSON'];
  generated: Scalars['Int'];
  source: MediaAssetSource;
};

export type MediaAssetRef = {
  id: Scalars['ID'];
  asset?: Maybe<MediaAsset>;
};

export type MediaAssetSource = {
  _id: Scalars['String'];
  bucket: Scalars['String'];
  object: Scalars['JSON'];
  uri: Scalars['String'];
  is_blacklisted: Scalars['Boolean'];
};

export type MicroConfig = BaseConfig & {
  legacy_id: Scalars['Int'];
  general: MicroGeneral;
  navigation: MicroNavigation;
  contact: MicroContact;
  social: MicroSocial;
  /** pages: [Page]! */
  pages: Scalars['JSON'];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
};

export type MicroContact = {
  full_bio?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  txt_number?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
};

export type MicroData = ConfigData & {
  /**
   * _id: String
   * id: String
   */
  index: MicroIndex;
  config: MicroConfig;
};

export type MicroGeneral = {
  name: Scalars['String'];
  description: Scalars['String'];
  default_content: Array<Maybe<Scalars['String']>>;
  keywords: Array<Scalars['String']>;
  linked_shows: Array<Scalars['String']>;
  thumbnail_image?: Maybe<Scalars['ImageRef']>;
  sixteen_by_nine?: Maybe<Scalars['ImageRef']>;
  ihr_channel_switch: Scalars['Boolean'];
  ihr_channel_type: Scalars['String'];
  ihr_podcast?: Maybe<CatalogRef>;
  ihr_live_stream?: Maybe<CatalogRef>;
  ihr_hero_image?: Maybe<Scalars['ImageRef']>;
  ihr_hero_color?: Maybe<Scalars['String']>;
  status: MicroGeneralStatus;
  active_start: Scalars['Int'];
  active_end: Scalars['Int'];
  password_switch: Scalars['Boolean'];
  password: Scalars['String'];
  hero_image?: Maybe<Scalars['ImageRef']>;
  hero_fallback_color?: Maybe<Scalars['String']>;
  simplereach_enabled?: Maybe<Scalars['Boolean']>;
  recommendations: Scalars['Boolean'];
  default_feed?: Maybe<Scalars['String']>;
};

export enum MicroGeneralStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type MicroIndex = BaseIndex & {
  id: Scalars['String'];
  brand_id: Scalars['String'];
  legacy_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  configs: Array<BaseIndexConfigPointer>;
  default: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['ImageRef']>;
  slug: Scalars['String'];
  facets: Array<Scalars['String']>;
  tags: Array<Maybe<Scalars['String']>>;
  own_brand: Scalars['String'];
  /** Microsite specific fields */
  primary_site: Scalars['String'];
  linked_shows: Array<Scalars['String']>;
  config: MicroConfig;
};


export type MicroIndexConfigArgs = {
  variant?: Maybe<Scalars['String']>;
};

export type MicroNavigation = {
  enable_nav_switch: Scalars['Boolean'];
  menu: Array<Maybe<SiteMenu>>;
};

export type MicrositeRef = {
  brand_id: Scalars['String'];
  slug: Scalars['String'];
};

export type MicroSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  facebook_name?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  youtube_name?: Maybe<Scalars['String']>;
  googleplus_switch?: Maybe<Scalars['Boolean']>;
  googleplus_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  instagram_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  pinterest_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  snapchat_name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  publish?: Maybe<Scalars['String']>;
  unpublish?: Maybe<Scalars['String']>;
  setType: Scalars['String'];
  removeType?: Maybe<Scalars['String']>;
  retarget?: Maybe<Scalars['Boolean']>;
  createDatasource?: Maybe<PubsubDatasource>;
  updateDatasource?: Maybe<PubsubDatasource>;
  deleteDatasource?: Maybe<Scalars['Boolean']>;
  publishToDatasources?: Maybe<Scalars['Boolean']>;
  cacheClear?: Maybe<CacheResponse>;
};


export type MutationPublishArgs = {
  type: Scalars['String'];
  record: PubsubPublishInput;
};


export type MutationUnpublishArgs = {
  type: Scalars['String'];
  ref: Scalars['String'];
};


export type MutationSetTypeArgs = {
  type: Scalars['String'];
  source: Scalars['String'];
  name: Scalars['String'];
  isSearchEnabled: Scalars['Boolean'];
};


export type MutationRemoveTypeArgs = {
  type: Scalars['String'];
};


export type MutationRetargetArgs = {
  type: Scalars['String'];
  fromRefId: Scalars['String'];
  toRefId: Scalars['String'];
};


export type MutationCreateDatasourceArgs = {
  input: PubsubDatasourceInput;
  populate?: Maybe<PubsubPopulateInput>;
};


export type MutationUpdateDatasourceArgs = {
  _id: Scalars['ID'];
  ops: Array<PubsubUpdateDatasourceOperationInput>;
};


export type MutationDeleteDatasourceArgs = {
  _id: Scalars['ID'];
};


export type MutationPublishToDatasourcesArgs = {
  type: Scalars['String'];
  record: PubsubPublishToDatasourcesInput;
};


export type MutationCacheClearArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};

export type Page = {
  name: Scalars['String'];
  path: Scalars['String'];
  pathVariables?: Maybe<Array<Scalars['String']>>;
  view_name: Scalars['String'];
  type: Scalars['String'];
  designation: Scalars['String'];
  primary?: Maybe<Scalars['String']>;
  blocks: Array<PageBlock>;
  block: PageBlock;
};


export type PageBlockArgs = {
  id: Scalars['String'];
};

export type PageBlock = {
  id: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
  disabled?: Maybe<Scalars['Boolean']>;
};

export type PlaybackRights = {
  onDemand?: Maybe<Scalars['Boolean']>;
};

export type PlaybackRightsProjection = {
  onDemand?: Maybe<Scalars['Boolean']>;
};

export type PlayedTrack = {
  track: PnpTrack;
  count: Scalars['Int'];
};

export type PlaylistRef = {
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  collection?: Maybe<Collection>;
};

export type PnpTrack = {
  artistId: Scalars['Int'];
  albumId: Scalars['Int'];
  trackId: Scalars['Int'];
  title: Scalars['String'];
  trackDuration?: Maybe<Scalars['Int']>;
  imagePath?: Maybe<Scalars['String']>;
  explicitLyrics: Scalars['Boolean'];
  version?: Maybe<Scalars['String']>;
  lyricsId?: Maybe<Scalars['Int']>;
  playbackRights?: Maybe<PlaybackRights>;
  artist?: Maybe<Artist>;
  artistName: Scalars['String'];
  albumName: Scalars['String'];
};

export type PnpTrackHistory = TrackHistory & {
  artistId: Scalars['Int'];
  albumId: Scalars['Int'];
  trackId: Scalars['Int'];
  title: Scalars['String'];
  trackDuration?: Maybe<Scalars['Int']>;
  imagePath?: Maybe<Scalars['String']>;
  explicitLyrics?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  lyricsId?: Maybe<Scalars['Int']>;
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  trackDurationInSec?: Maybe<Scalars['Int']>;
  dataSource?: Maybe<Scalars['String']>;
  artist?: Maybe<Artist>;
  artistName: Scalars['String'];
  albumName: Scalars['String'];
};

export type Podcast = {
  id: Scalars['Int'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  lastUpdated: Scalars['Float'];
  slug: Scalars['String'];
  isExternal: Scalars['Boolean'];
  imageUrl: Scalars['String'];
  customLinks: Array<CustomLink>;
  socialMediaLinks: Array<SocialMediaLink>;
  heroImage?: Maybe<Scalars['String']>;
  heroImageColor?: Maybe<Scalars['String']>;
  hostIds: Array<Scalars['String']>;
  showType?: Maybe<Scalars['String']>;
  episodes: PodcastEpisodes;
};


export type PodcastEpisodesArgs = {
  paging?: Maybe<AmpPageInput>;
};

export type PodcastEpisode = {
  id: Scalars['Int'];
  podcastId: Scalars['Int'];
  podcastSlug: Scalars['String'];
  title: Scalars['String'];
  duration: Scalars['Int'];
  isExplicit: Scalars['Boolean'];
  description: Scalars['String'];
  startDate: Scalars['Float'];
  imageUrl: Scalars['String'];
};

export type PodcastEpisodes = {
  pageInfo: AmpPageInfo;
  items: Array<PodcastEpisode>;
};

export type PodcastFollow = {
  profileId: Scalars['Int'];
  podcastId: Scalars['Int'];
  dateCreated: Scalars['Float'];
  lastPlayed: Scalars['Float'];
  settings: PodcastFollowSettings;
};

export type PodcastFollowSettings = {
  notifications: Scalars['Boolean'];
};

export type PodcastRef = {
  id: Scalars['ID'];
  /** @deprecated Field no longer supported */
  amp?: Maybe<Podcast>;
};

export type PodcastWithFollow = {
  podcast: Podcast;
  follow?: Maybe<PodcastFollow>;
};

export type PronouncementRef = {
  is_primary: Scalars['Boolean'];
  utterance: Scalars['String'];
};

export type PublicTrack = {
  id: Scalars['Int'];
  title: Scalars['String'];
  artistId: Scalars['Int'];
  artistName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  albumId: Scalars['Int'];
  albumName: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  lyricsId?: Maybe<Scalars['Int']>;
  publisher?: Maybe<Scalars['String']>;
  trackNumber: Scalars['Int'];
  playbackRights: PlaybackRightsProjection;
  explicitLyrics?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Int']>;
};

export type PublishSummary = {
  author: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PubsubDatasource = {
  _id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  references: Array<Scalars['String']>;
  primary: Scalars['String'];
  segments: Array<Scalars['String']>;
  scopes: Array<PubsubScope>;
  subscriptionPerScope: Scalars['Boolean'];
  what: Array<Scalars['String']>;
  subscriptions?: Maybe<Array<PubsubDsSubscription>>;
  subscription?: Maybe<PubsubDsSubscription>;
};


export type PubsubDatasourceSubscriptionArgs = {
  _id?: Maybe<Scalars['ID']>;
  segment?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type PubsubDatasourceInput = {
  /** Friendly name for the data source. */
  name: Scalars['String'];
  /** Type of content to subscribe to. */
  type: Scalars['String'];
  /** Client specific identification of how the data source is used. At least one is required. */
  references: Array<Scalars['String']>;
  primary: Scalars['String'];
  /** List of identifiers for the site, to be used for exclusions and querying. */
  segments: Array<Scalars['String']>;
  scopes: Array<PubsubScopeInput>;
  subscriptionPerScope: Scalars['Boolean'];
  what: Array<Scalars['String']>;
};

export type PubsubDatasourceListFilter = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  references?: Maybe<Array<Scalars['String']>>;
};

export type PubsubDistribution = {
  tags: Array<Scalars['String']>;
};

export type PubsubDistributionInput = {
  tags: Array<Scalars['String']>;
  operator?: Maybe<PubsubDistributionOperator>;
};

export enum PubsubDistributionOperator {
  And = 'AND'
}

export type PubsubDsSubscription = {
  _id: Scalars['ID'];
  datasourceId: Scalars['ID'];
  where: Array<Scalars['String']>;
  what: Array<Scalars['String']>;
  subject: Array<Scalars['String']>;
  query?: Maybe<PubsubDsSubscriptionQueryResult>;
};


export type PubsubDsSubscriptionQueryArgs = {
  pageToken?: Maybe<Scalars['ID']>;
  size?: Maybe<Scalars['Int']>;
  what?: Maybe<Array<Scalars['String']>>;
};

export type PubsubDsSubscriptionQueryResult = {
  items: Array<PubsubPublishRecord>;
  page: PubsubPageInfo;
};

export type PubsubExclusionInput = {
  tags: Array<Scalars['String']>;
};

export type PubsubFunctions = {
  get?: Maybe<PubsubPublishRecord>;
  query?: Maybe<PubsubQueryOutput>;
  search: PubsubSearchOutput;
};


export type PubsubFunctionsGetArgs = {
  type: Scalars['String'];
  select: PubsubSelectInput;
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
};


export type PubsubFunctionsQueryArgs = {
  type: Scalars['String'];
  query: PubsubQueryInput;
};


export type PubsubFunctionsSearchArgs = {
  type: Scalars['String'];
  search: PubsubSearchInput;
};

export type PubsubLinksInInput = {
  /** Field name to fetch links for. */
  name: Scalars['String'];
  /** Query a particular pubsub type for results. */
  type: Scalars['String'];
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  /** Page size for results. Default: 25 */
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubLinksOutInput = {
  /** Field name to fetch links for. */
  name: Scalars['String'];
  /** Narrow results to a particular pubsub type. */
  type?: Maybe<Scalars['String']>;
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubPageInfo = {
  prevToken?: Maybe<Scalars['ID']>;
  nextToken?: Maybe<Scalars['ID']>;
  itemTokens: Array<Scalars['ID']>;
};

export type PubsubPopulateInput = {
  /** Link all matching records to the datasource, instead of stopping at a set amount. */
  all?: Maybe<Scalars['Boolean']>;
  /** Link up to this number of matching records to the datasource, and stop. Ignored if all=true */
  size?: Maybe<Scalars['Int']>;
};

export type PubsubPublishInput = {
  ref_id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  summary: PubsubPublishSummaryInput;
  payload?: Maybe<Scalars['JSON']>;
  lookup?: Maybe<Array<Maybe<Scalars['String']>>>;
  subscription: Array<PubsubDistributionInput>;
  exclusion?: Maybe<Array<PubsubExclusionInput>>;
  pub_start: Scalars['Float'];
  pub_until?: Maybe<Scalars['Float']>;
  links?: Maybe<Array<PubsubPublishLinkInput>>;
};

export type PubsubPublishLinkInput = {
  type: Scalars['String'];
  name: Scalars['String'];
  ref_id: Scalars['String'];
};

export type PubsubPublishRecord = {
  id?: Maybe<Scalars['ID']>;
  ref_id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  summary: PubsubPublishSummary;
  payload?: Maybe<Scalars['JSON']>;
  subscription: Array<PubsubDistribution>;
  exclusion: Array<PubsubDistribution>;
  pub_start: Scalars['Float'];
  pub_until?: Maybe<Scalars['Float']>;
  pub_changed?: Maybe<Scalars['Float']>;
  pub_touched: Scalars['Float'];
  /** @deprecated use linksIn, linksOut */
  links: PubsubQueryOutput;
  /** Fetch content which links to this record. */
  linksIn: PubsubQueryOutput;
  /** Fetch content this record links to. */
  linksOut: Array<PubsubPublishRecord>;
  keys: Array<Scalars['String']>;
};


export type PubsubPublishRecordLinksArgs = {
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  link_type?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type PubsubPublishRecordLinksInArgs = {
  query: PubsubLinksInInput;
};


export type PubsubPublishRecordLinksOutArgs = {
  query: PubsubLinksOutInput;
};

export type PubsubPublishSummary = {
  author: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PubsubPublishSummaryInput = {
  author: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PubsubPublishToDatasourcesInput = {
  ref_id: Scalars['ID'];
  targets: Array<PubsubTargetsInput>;
};

export type PubsubQueryInput = {
  lookup?: Maybe<Array<Scalars['String']>>;
  subscription?: Maybe<Array<PubsubDistributionInput>>;
  exclusion?: Maybe<Array<PubsubExclusionInput>>;
  subject?: Maybe<Array<Scalars['String']>>;
  pub_start?: Maybe<Scalars['Float']>;
  pub_after?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortField?: Maybe<PubsubSortField>;
};

export type PubsubQueryOutput = {
  items: Array<PubsubPublishRecord>;
  query: Scalars['JSON'];
  /** @deprecated Use `page.nextCursor`, if present this query has more results. */
  has_more: Scalars['Boolean'];
  /** @deprecated Use `page.nextCursor` instead. */
  next?: Maybe<Scalars['String']>;
  page: PubsubPageInfo;
};

export type PubsubScope = {
  id: Scalars['String'];
  segments?: Maybe<Array<Scalars['String']>>;
};

export type PubsubScopeInput = {
  id: Scalars['String'];
  segments?: Maybe<Array<Scalars['String']>>;
};

export type PubsubSearchInput = {
  query: Scalars['JSON'];
  lookup?: Maybe<Array<Scalars['String']>>;
  subscription?: Maybe<Array<PubsubDistributionInput>>;
  exclusion?: Maybe<Array<PubsubExclusionInput>>;
  subject?: Maybe<Array<Scalars['String']>>;
  pub_start?: Maybe<Scalars['Float']>;
  pub_after?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  sortField?: Maybe<PubsubSortField>;
  sort?: Maybe<Scalars['JSON']>;
};

export type PubsubSearchOutput = {
  items: Array<PubsubPublishRecord>;
  search: Scalars['JSON'];
  /** @deprecated Use `page.nextCursor`, if present this search has more results. */
  has_more: Scalars['Boolean'];
  /** @deprecated Use `page.nextCursor` instead. */
  next?: Maybe<Scalars['String']>;
  page: PubsubPageInfo;
};

export type PubsubSelectInput = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export enum PubsubSortField {
  PubStart = 'PUB_START',
  PubTouched = 'PUB_TOUCHED'
}

export type PubsubTargetsInput = {
  what: Array<Scalars['String']>;
  where: Array<Scalars['String']>;
  operator?: Maybe<PubsubDistributionOperator>;
};

export type PubsubUpdateDatasourceOperationInput = {
  setAttributes?: Maybe<PubsubDatasourceInput>;
  populate?: Maybe<PubsubPopulateInput>;
};

export type PwsCoastAppContentModule = {
  featured: PwsCoastFeaturedResult;
  search: PwsCoastSearchResponse;
};


export type PwsCoastAppContentModuleFeaturedArgs = {
  shows?: Maybe<Scalars['Int']>;
  articles?: Maybe<Scalars['Int']>;
  photos?: Maybe<Scalars['Int']>;
  sections?: Maybe<Scalars['Int']>;
  platform?: Maybe<PwsCoastFeaturedPlatform>;
};


export type PwsCoastAppContentModuleSearchArgs = {
  term: Scalars['String'];
  doMerge?: Maybe<Scalars['Boolean']>;
  includeArticles?: Maybe<Scalars['Boolean']>;
  includeGuests?: Maybe<Scalars['Boolean']>;
  includeShows?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  platform?: Maybe<PwsCoastSearchPlatform>;
};

export type PwsCoastFeaturedDefaultPlatform = {
  value?: Maybe<PwsCoastFeaturedDefaultPlatformValue>;
};

export type PwsCoastFeaturedDefaultPlatformValue = {
  shows: Array<PwsCoastFeaturedItem>;
  articles: Array<PwsCoastFeaturedItem>;
  photos: Array<PwsCoastFeaturedItem>;
};

export type PwsCoastFeaturedDesktopPlatform = {
  value: Array<Array<PwsCoastFeaturedItem>>;
};

export type PwsCoastFeaturedItem = {
  slug?: Maybe<Scalars['String']>;
  ref_id: Scalars['String'];
  pub_start: DateRef;
  content_type: PwsCoastFeaturedItemContentType;
  summary: PwsCoastFeaturedItemSummary;
};

export enum PwsCoastFeaturedItemContentType {
  Show = 'SHOW',
  Article = 'ARTICLE',
  Photo = 'PHOTO'
}

export type PwsCoastFeaturedItemSummary = {
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum PwsCoastFeaturedPlatform {
  Desktop = 'DESKTOP'
}

export type PwsCoastFeaturedResult = PwsCoastFeaturedDefaultPlatform | PwsCoastFeaturedDesktopPlatform;

export type PwsCoastSearchItem = {
  ref_id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  pub_start: DateRef;
  summary: PwsCoastSearchItemSummary;
  category: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  source_url?: Maybe<Scalars['String']>;
};

export type PwsCoastSearchItemSummary = {
  title: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export enum PwsCoastSearchPlatform {
  Desktop = 'DESKTOP',
  App = 'APP'
}

export type PwsCoastSearchResponse = PwsCoastSearchResponseMerged | PwsCoastSearchResponseByCategory;

export type PwsCoastSearchResponseByCategory = {
  value: PwsCoastSearchResponseByCategoryValue;
};

export type PwsCoastSearchResponseByCategoryValue = {
  show?: Maybe<PwsCoastSearchResponseCategory>;
  article?: Maybe<PwsCoastSearchResponseCategory>;
  guest?: Maybe<PwsCoastSearchResponseCategory>;
};

export type PwsCoastSearchResponseCategory = {
  items: Array<PwsCoastSearchItem>;
};

export type PwsCoastSearchResponseMerged = {
  value: PwsCoastSearchResponseMergedValue;
};

export type PwsCoastSearchResponseMergedValue = {
  items: Array<PwsCoastSearchItem>;
  count: Scalars['Int'];
};

export type PwsModules = {
  coastAppContent: PwsCoastAppContentModule;
};

/** These must be optional so we can properly handle errors/timeouts */
export type Query = {
  _service: Sites_Service;
  sites: SitesSites;
  adPositions: SitesAdPositions;
  onAir: SitesOnAir;
  get?: Maybe<PubsubPublishRecord>;
  getId?: Maybe<PubsubPublishRecord>;
  query?: Maybe<PubsubQueryOutput>;
  multi: Array<Maybe<PubsubQueryOutput>>;
  search: PubsubSearchOutput;
  datasources?: Maybe<Array<PubsubDatasource>>;
  datasource?: Maybe<PubsubDatasource>;
  subscription?: Maybe<PubsubDsSubscription>;
  charts: ChartFunctions;
  pws: PwsModules;
  content?: Maybe<ContentFunctions>;
  /** @deprecated This uses site-manager 4 instead of sites */
  site?: Maybe<SiteFunctions>;
  artist?: Maybe<ArtistFunctions>;
  genre?: Maybe<GenreFunctions>;
  leads: Array<Card>;
  taxonomy?: Maybe<TaxonomyFunctions>;
  calendar?: Maybe<CalendarFunctions>;
  aptivada?: Maybe<AptivadaFunctions>;
  pubsub: PubsubFunctions;
  /** @deprecated Field no longer supported */
  amp: AmpFunctions;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryGetArgs = {
  type: Scalars['String'];
  slug: Scalars['String'];
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryGetIdArgs = {
  type: Scalars['String'];
  id: Scalars['String'];
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryQueryArgs = {
  type: Scalars['String'];
  query: PubsubQueryInput;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryMultiArgs = {
  type: Scalars['String'];
  queries: Array<PubsubQueryInput>;
  limit?: Maybe<Scalars['Int']>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QuerySearchArgs = {
  type: Scalars['String'];
  search: PubsubSearchInput;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryDatasourcesArgs = {
  filter?: Maybe<PubsubDatasourceListFilter>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryDatasourceArgs = {
  _id: Scalars['ID'];
};


/** These must be optional so we can properly handle errors/timeouts */
export type QuerySubscriptionArgs = {
  _id?: Maybe<Scalars['ID']>;
  segment?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QuerySiteArgs = {
  select: SiteQuery;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryArtistArgs = {
  artistId: Scalars['Int'];
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryGenreArgs = {
  genreId: Scalars['Int'];
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryLeadsArgs = {
  query: QueryInput;
  locale?: Maybe<Scalars['String']>;
};


/** These must be optional so we can properly handle errors/timeouts */
export type QueryAptivadaArgs = {
  accountId: Scalars['String'];
};

export type QueryInput = {
  lookup?: Maybe<Array<Scalars['String']>>;
  subscription?: Maybe<Array<SubscriptionInput>>;
  exclusion?: Maybe<Array<SubscriptionInput>>;
  subject?: Maybe<Array<Scalars['String']>>;
  pub_start?: Maybe<Scalars['Float']>;
  pub_after?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type RawSchedule = {
  associated_microsite?: Maybe<Scalars['String']>;
  batch_id: Scalars['Int'];
  core_show_id: Scalars['Int'];
  core_station_id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  personality?: Maybe<Scalars['String']>;
  show: Scalars['String'];
  site: Scalars['String'];
  site_id: Scalars['String'];
  station: Scalars['String'];
  tz_id: Scalars['String'];
  schedule: RawScheduleWeek;
};

export type RawScheduleDay = {
  start: Scalars['Float'];
  start_local: Scalars['String'];
  stop: Scalars['Float'];
  stop_local: Scalars['String'];
};

export type RawScheduleWeek = {
  su?: Maybe<RawScheduleDay>;
  mo?: Maybe<RawScheduleDay>;
  tu?: Maybe<RawScheduleDay>;
  we?: Maybe<RawScheduleDay>;
  th?: Maybe<RawScheduleDay>;
  fr?: Maybe<RawScheduleDay>;
  sa?: Maybe<RawScheduleDay>;
};

export type Redirect = {
  sourceType?: Maybe<Scalars['String']>;
  source: RedirectSource;
  destinationType?: Maybe<Scalars['String']>;
  destination: RedirectDestination;
  startDate?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Float']>;
  permanent: Scalars['Boolean'];
};

export type RedirectDestination = {
  url?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export type RedirectQuery = {
  path: Scalars['String'];
};

export type RedirectSource = {
  path?: Maybe<Scalars['String']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['Boolean']>;
};

export type Schedule = {
  timezone?: Maybe<Scalars['String']>;
  /**
   * This represents the current show that is on the air for this brand.
   * It's quite possible this could be null as *most* brands have gaps in their
   * schedule where shows do not exist.
   */
  current?: Maybe<ScheduleEntry>;
  /**
   * This represents the current show that is on the air for this brand.
   * It's quite possible this could be null as *most* brands have gaps in their
   * schedule where shows do not exist.
   */
  upcoming: Array<ScheduleEntry>;
};


export type ScheduleUpcomingArgs = {
  next?: Maybe<Scalars['Int']>;
};

/**
 * This contains an array of possible start and stop values to make
 * representing the appropriate time or deserializing the date info
 * easy on the client.
 * 
 * For links, image and content about the show's site checkout the
 * `destination` attribute.
 */
export type ScheduleEntry = {
  core_show_id: Scalars['Int'];
  core_station_id: Scalars['Int'];
  destination?: Maybe<ScheduleEntryDestination>;
  name: Scalars['String'];
  on_now: Scalars['Boolean'];
  show: Scalars['String'];
  site_id: Scalars['String'];
  site: Scalars['String'];
  start_date: Scalars['String'];
  start_eng: Scalars['String'];
  start_ms: Scalars['Float'];
  start_time_12: Scalars['String'];
  station: Scalars['String'];
  stop_date: Scalars['String'];
  stop_eng: Scalars['String'];
  stop_ms: Scalars['Float'];
  stop_time_12: Scalars['String'];
};

export type ScheduleEntryDestination = {
  host: Scalars['String'];
  href: Scalars['String'];
  image_ref?: Maybe<Scalars['ImageRef']>;
  link?: Maybe<Scalars['String']>;
  linked_to_microsite: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
};

export type SiteAds = {
  dfp_switch?: Maybe<Scalars['Boolean']>;
  interstitial_switch?: Maybe<Scalars['Boolean']>;
  simplifi_switch?: Maybe<Scalars['Boolean']>;
  simplifi_cid?: Maybe<Scalars['String']>;
  simplifi_segment?: Maybe<Scalars['String']>;
  simplifi_sifi_tuid?: Maybe<Scalars['String']>;
  header_bidding: Scalars['Boolean'];
  facebook_ad_track_pixel?: Maybe<Scalars['String']>;
  facebook_ad_track_pixel_switch?: Maybe<Scalars['Boolean']>;
  facebook_ad_track_pixel_id?: Maybe<Scalars['String']>;
  yieldmo_switch?: Maybe<Scalars['Boolean']>;
};

export type SiteAlert = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Float']>;
  end_date?: Maybe<Scalars['Float']>;
};

export type SiteAnalytics = {
  adobe_library?: Maybe<Scalars['String']>;
  omniture_switch?: Maybe<Scalars['Boolean']>;
  omniture_id_poc_control?: Maybe<Scalars['String']>;
  omniture_id_market?: Maybe<Scalars['String']>;
  google_analytics_switch?: Maybe<Scalars['Boolean']>;
  poc_primary_analytics_id?: Maybe<Scalars['String']>;
  poc_secondary_analytics_id?: Maybe<Scalars['String']>;
  quantcast_switch?: Maybe<Scalars['Boolean']>;
  quantcast_account_id?: Maybe<Scalars['String']>;
  charbeat_switch?: Maybe<Scalars['Boolean']>;
  chartbeat_account_id?: Maybe<Scalars['String']>;
  parsely_switch?: Maybe<Scalars['Boolean']>;
  parsely_account_id?: Maybe<Scalars['String']>;
};

export type SiteConfig = BaseConfig & {
  page: Page;
  pages: Scalars['JSON'];
  feeds?: Maybe<Array<DatasourceFeed>>;
  queries?: Maybe<Array<DatasourceQuery>>;
  /** // ownership: String! */
  menu: Array<SiteMenu>;
  menu_footer: Array<SiteMenu>;
  general: SiteGeneral;
  alert: SiteAlert;
  redirects: Array<Redirect>;
  social: SiteSocial;
  contact: SiteContact;
  partners: SitePartners;
  design: SiteDesign;
  hide: SiteHide;
  ads: SiteAds;
  analytics: SiteAnalytics;
  navigation: SiteNavigation;
};


export type SiteConfigPageArgs = {
  viewName?: Maybe<Scalars['String']>;
};

export type SiteContact = {
  page_intro?: Maybe<Scalars['String']>;
  show_social_switch?: Maybe<Scalars['Boolean']>;
  phone_text_numbers_switch?: Maybe<Scalars['Boolean']>;
  request_phone_number?: Maybe<Scalars['String']>;
  business_phone_number?: Maybe<Scalars['String']>;
  traffic_tip_phone?: Maybe<Scalars['String']>;
  newsroom_phone?: Maybe<Scalars['String']>;
  sms_number?: Maybe<Scalars['String']>;
  prize_pickup_directions_switch?: Maybe<Scalars['Boolean']>;
  prize_pickup_info?: Maybe<Scalars['String']>;
  location_address1?: Maybe<Scalars['String']>;
  location_address2?: Maybe<Scalars['String']>;
  location_city?: Maybe<Scalars['String']>;
  location_state?: Maybe<Scalars['String']>;
  location_zip?: Maybe<Scalars['String']>;
  office_hours?: Maybe<Scalars['String']>;
  email_form_switch?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<SiteContactEntry>>;
  employment_info_switch?: Maybe<Scalars['String']>;
  eeo_report_asset?: Maybe<Scalars['ImageRef']>;
  eeo_report?: Maybe<Scalars['String']>;
  employment_contact_name?: Maybe<Scalars['String']>;
  employment_link_override?: Maybe<Scalars['String']>;
  tech_contact?: Maybe<Scalars['String']>;
  programming_contact?: Maybe<Scalars['String']>;
};

export type SiteContactEntry = {
  type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SiteData = ConfigData & {
  /**
   * _id: String
   * id: String
   */
  index: SiteIndex;
  config: SiteConfig;
  surrogate_keys: Array<Scalars['String']>;
};

export type SiteDesign = {
  logo_best_res?: Maybe<Scalars['ImageRef']>;
  station_logo?: Maybe<Scalars['ImageRef']>;
  logo_favicon?: Maybe<Scalars['ImageRef']>;
  link_color?: Maybe<Scalars['String']>;
  read_next_stripe?: Maybe<Scalars['String']>;
  nav_bg_color?: Maybe<Scalars['String']>;
  nav_text_color?: Maybe<Scalars['String']>;
  nav_ihr_link_color?: Maybe<Scalars['String']>;
  player_bar_switch?: Maybe<Scalars['Boolean']>;
  ihr_hero_image?: Maybe<Scalars['ImageRef']>;
  ihr_hero_color?: Maybe<Scalars['String']>;
};

export type SiteFunctions = {
  summary: SiteSummary;
  /**
   * @deprecated This provides the entire config structure, it assumes that all
   * output is site content not microsite content so there could be errors when
   * trying to address site-only fields.
   */
  config?: Maybe<ConfigData>;
  adConfig?: Maybe<AdConfig>;
  redirect?: Maybe<Scalars['String']>;
  timeline: Array<ContentRecord>;
  onairRaw?: Maybe<Array<RawSchedule>>;
  /** This function processes the raw scheduling data from `onairRaw` into useful output. */
  schedule?: Maybe<Schedule>;
  leads: Array<Card>;
  /** Get calendar event info for the site. Default start date is "now". */
  events: Array<CalendarEvent>;
  eventCategories: Array<Scalars['String']>;
  stream?: Maybe<Stream>;
  microsites?: Maybe<Array<MicrositeRef>>;
  sitemap: SiteMapIndex;
  feed?: Maybe<Array<Maybe<DatasourceFeedItem>>>;
};


export type SiteFunctionsRedirectArgs = {
  path: Scalars['String'];
};


export type SiteFunctionsTimelineArgs = {
  topic?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
};


export type SiteFunctionsLeadsArgs = {
  locale?: Maybe<Scalars['String']>;
};


export type SiteFunctionsEventsArgs = {
  category?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SiteFunctionsEventCategoriesArgs = {
  start_time?: Maybe<Scalars['Float']>;
  end_time?: Maybe<Scalars['Float']>;
};


export type SiteFunctionsFeedArgs = {
  id?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['JSON']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  locale?: Maybe<Scalars['String']>;
};

export type SiteGeneral = {
  name: Scalars['String'];
  positioner?: Maybe<Scalars['String']>;
  default_content?: Maybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  market: Scalars['String'];
  weather_market?: Maybe<Scalars['String']>;
  geo_latitude?: Maybe<Scalars['String']>;
  geo_longitude?: Maybe<Scalars['String']>;
  tz_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  broadcast_frequency: Scalars['String'];
  broadcast_band: Scalars['String'];
  facility_id?: Maybe<Scalars['String']>;
  broadcast_call_letters: Scalars['String'];
  broadcast_facilities: Array<BroadcastFacility>;
  default_podcasts?: Maybe<Array<Scalars['String']>>;
  default_feed?: Maybe<Scalars['String']>;
  google_amp_switch?: Maybe<Scalars['Boolean']>;
};

export type SiteHide = {
  hide_switch?: Maybe<Scalars['Boolean']>;
  hide_start_date?: Maybe<Scalars['Float']>;
  hide_end_date?: Maybe<Scalars['Float']>;
  url_redirect?: Maybe<Scalars['String']>;
};

/** TODO Should probably split these into interfaces with Site/Micro/Index etc... */
export type SiteIndex = BaseIndex & {
  id: Scalars['String'];
  brand_id: Scalars['String'];
  default: Scalars['String'];
  description: Scalars['String'];
  legacy_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  own_brand: Scalars['String'];
  slug: Scalars['String'];
  configs: Array<BaseIndexConfigPointer>;
  facets: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  image?: Maybe<Scalars['ImageRef']>;
  /** Site specific fields */
  hosts?: Maybe<Array<SiteIndexHost>>;
  linked?: Maybe<Array<MicroData>>;
  market?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
};

export type SiteIndexHost = {
  design?: Maybe<Scalars['String']>;
  hostname: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
};

export type SiteMap = {
  primary_origin?: Maybe<Scalars['String']>;
  records?: Maybe<Scalars['Float']>;
  items: Array<Maybe<SiteMapItem>>;
};

export type SiteMapIndex = {
  pages: SiteMap;
  timeline: SiteMap;
};

export type SiteMapItem = {
  loc: Scalars['String'];
  lastmod?: Maybe<Scalars['String']>;
  changefreq: Scalars['String'];
};

export type SiteMenu = {
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<SiteMenu>>>;
  target?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
};

export type SiteNavigation = {
  listen_live_switch?: Maybe<Scalars['Boolean']>;
  contest_nav_switch?: Maybe<Scalars['Boolean']>;
  search_text?: Maybe<Scalars['Boolean']>;
  user_menu_switch?: Maybe<Scalars['Boolean']>;
  connect_menu_switch?: Maybe<Scalars['Boolean']>;
  show_public_file_switch?: Maybe<Scalars['Boolean']>;
  search_switch?: Maybe<Scalars['Boolean']>;
};

export type SitePartners = {
  iheartradio_switch?: Maybe<Scalars['Boolean']>;
  stream_id?: Maybe<Scalars['String']>;
  ihr_stream?: Maybe<CatalogRef>;
  ihr_on_demand?: Maybe<Scalars['String']>;
  ihr_playlist_max?: Maybe<Scalars['String']>;
  ihr_player_playlist?: Maybe<Scalars['Boolean']>;
  national_programming_switch?: Maybe<Scalars['Boolean']>;
  format?: Maybe<Scalars['String']>;
  exacttarget_switch?: Maybe<Scalars['Boolean']>;
  exacttarget_business_unit_id?: Maybe<Scalars['String']>;
  outbrain_switch?: Maybe<Scalars['Boolean']>;
  total_traffic_switch?: Maybe<Scalars['Boolean']>;
  traffic_market?: Maybe<Scalars['String']>;
  traffic_zoom?: Maybe<Scalars['String']>;
  ldr_key_id?: Maybe<Scalars['String']>;
  tynt_switch?: Maybe<Scalars['Boolean']>;
  aptivada_switch?: Maybe<Scalars['Boolean']>;
  perfect_pixel_switch?: Maybe<Scalars['Boolean']>;
  alexa_pronouncer?: Maybe<Scalars['String']>;
  google_pronouncer?: Maybe<Scalars['String']>;
  ihr_show_in_iheart_switch?: Maybe<Scalars['Boolean']>;
  simple_reach_switch?: Maybe<Scalars['Boolean']>;
  simple_reach_id?: Maybe<Scalars['String']>;
};

export type SiteQuery = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  stream?: Maybe<Scalars['Int']>;
  genre?: Maybe<Scalars['Int']>;
  podcast?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['String']>;
};

export type SiteRef = {
  id: Scalars['ID'];
  sites?: Maybe<SitesIndexRecord>;
  taxo?: Maybe<Tag>;
};

export enum Sites_Lookup_By {
  Id = 'ID',
  Slug = 'SLUG',
  Brand = 'BRAND',
  Hostname = 'HOSTNAME',
  Show = 'SHOW',
  PrimaryParentOf = 'PRIMARY_PARENT_OF',
  Stream = 'STREAM',
  Genre = 'GENRE',
  Podcast = 'PODCAST'
}

export enum Sites_Onair_Day {
  Su = 'SU',
  Mo = 'MO',
  Tu = 'TU',
  We = 'WE',
  Th = 'TH',
  Fr = 'FR',
  Sa = 'SA'
}

export enum Sites_Onair_Source {
  Core = 'CORE',
  User = 'USER'
}

export enum Sites_Referenced_By {
  EmbeddedIn = 'EMBEDDED_IN'
}

export type Sites_Service = {
  /**
   * The sdl representing the federated service capabilities. Includes federation
   * directives, removes federation types, and includes rest of full schema after
   * schema directives have been applied
   */
  sdl?: Maybe<Scalars['String']>;
};

export type SitesAdBreakpoint = {
  breakpoint: Scalars['Int'];
  sizes: Array<SitesAdSize>;
};

export type SitesAddPageInput = {
  name: Scalars['String'];
  configId: Scalars['ID'];
  layoutId: Scalars['String'];
  displayName: Scalars['String'];
  path: Scalars['String'];
  pathVariables: Scalars['JSON'];
  tags: Array<Scalars['String']>;
  blocks: Array<SitesBlockInput>;
};

export type SitesAdPositionFilterInput = {
  templates?: Maybe<Array<Scalars['String']>>;
  positionIds?: Maybe<Array<Scalars['String']>>;
};

export type SitesAdPositionInput = {
  positionId: Scalars['String'];
  adSplit: Scalars['Int'];
  forceLoad: Scalars['Boolean'];
  templates: Array<Scalars['String']>;
};

export type SitesAdPositionMutation = {
  update: SitesAdPositionRecord;
  delete?: Maybe<Scalars['Boolean']>;
  addSize: SitesAdPositionRecord;
  remSize: SitesAdPositionRecord;
};


export type SitesAdPositionMutationUpdateArgs = {
  payload: SitesAdPositionInput;
};


export type SitesAdPositionMutationAddSizeArgs = {
  breakpoint: Scalars['Int'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};


export type SitesAdPositionMutationRemSizeArgs = {
  breakpoint: Scalars['Int'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type SitesAdPositionMutations = {
  create: SitesAdPositionRecord;
  find: SitesAdPositionMutation;
};


export type SitesAdPositionMutationsCreateArgs = {
  payload: SitesAdPositionInput;
};


export type SitesAdPositionMutationsFindArgs = {
  id: Scalars['ID'];
};

export type SitesAdPositionRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  positionId: Scalars['String'];
  /** @deprecated AdPositionRecord now supports multiple templates, use the `AdPositionRecord.templates` resolver instead. */
  template: Scalars['String'];
  templates: Array<Scalars['String']>;
  adSplit: Scalars['Int'];
  forceLoad: Scalars['Boolean'];
  breakpoints: Array<SitesAdBreakpoint>;
};

export type SitesAdPositionResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesAdPositionRecord>;
};

export type SitesAdPositions = {
  /** @deprecated Non-paginated, use filter2 */
  filter: Array<SitesAdPositionRecord>;
  filter2: SitesAdPositionResults;
  find?: Maybe<SitesAdPositionRecord>;
  findMany: Array<SitesAdPositionRecord>;
};


export type SitesAdPositionsFilterArgs = {
  filter: SitesAdPositionFilterInput;
};


export type SitesAdPositionsFilter2Args = {
  filter: SitesAdPositionFilterInput;
  paging?: Maybe<SitesPageInput>;
};


export type SitesAdPositionsFindArgs = {
  id: Scalars['ID'];
};


export type SitesAdPositionsFindManyArgs = {
  ids: Array<Scalars['ID']>;
};

export type SitesAdSize = {
  w: Scalars['Int'];
  h: Scalars['Int'];
};

export type SitesBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SitesBlockInput = {
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesScheduleInput>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SitesBlockOverride = {
  id: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['JSON'];
};

export type SitesBlockOverrideInput = {
  id: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['JSON'];
};

export type SitesBrandingCommon = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<MediaAssetRef>;
  description?: Maybe<Scalars['String']>;
};

export type SitesBroadcastFacilitiesIntegration = SitesIntegrationPlugin & {
  type: Scalars['String'];
  data: Scalars['JSON'];
  plugin: SitesBroadcastFacilitiesIntegrationData;
};

export type SitesBroadcastFacilitiesIntegrationData = {
  broadcastFacilities: Array<SitesBroadcastFacility>;
};

export type SitesBroadcastFacility = {
  facilityId?: Maybe<Scalars['String']>;
  broadcastFrequency?: Maybe<Scalars['String']>;
  broadcastBand?: Maybe<Scalars['String']>;
  broadcastCallLetters?: Maybe<Scalars['String']>;
  applicationNotices: Array<SitesBroadcastFacilityApplicationNotice>;
  politicalLinkOverride?: Maybe<Scalars['String']>;
};

export type SitesBroadcastFacilityApplicationNotice = {
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type SitesCatalogItem = {
  name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  kind: Scalars['String'];
  country?: Maybe<Scalars['String']>;
};

export type SitesCommonConfigRecord = {
  count: Scalars['Int'];
  config: SitesConfigInternalRecord;
};

export type SitesCommonConfigsInput = {
  tags?: Maybe<Array<Scalars['String']>>;
  orTags?: Maybe<Scalars['Boolean']>;
  template: Scalars['String'];
};

export type SitesCommonsConfig = {
  branding?: Maybe<SitesBrandingCommon>;
  location?: Maybe<SitesLocationCommon>;
  content?: Maybe<SitesContentCommon>;
  design?: Maybe<SitesDesignCommon>;
  contact?: Maybe<SitesContactCommon>;
  social?: Maybe<SitesSocialCommon>;
  partners?: Maybe<SitesPartnersCommon>;
};

export type SitesConfigCreateInput = {
  indexId: Scalars['ID'];
  name: Scalars['String'];
  template: Scalars['String'];
  parentLink?: Maybe<Scalars['String']>;
  memberships?: Maybe<Array<Scalars['String']>>;
};

export type SitesConfigFilterInput = {
  indexIds?: Maybe<Array<Scalars['String']>>;
  memberships?: Maybe<Array<Scalars['String']>>;
};

export type SitesConfigInternalRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  name: Scalars['String'];
  indexId: Scalars['String'];
  parentLink: Scalars['String'];
  template: Scalars['String'];
  redirects: Array<SitesRedirect>;
  searchOverrides: Array<SitesSearchOverride>;
  queries: Array<SitesQueryDefinition>;
  feeds: Array<SitesFeed>;
  userConfig: Scalars['JSON'];
  isDeleted: Scalars['Boolean'];
  stack: Array<SitesConfigInternalRecord>;
  merged?: Maybe<SitesConfigRecord>;
  datasources: Array<SitesDatasourceRecord>;
};

export type SitesConfigInternalResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesConfigInternalRecord>;
};

export type SitesConfigMutation = {
  rename: SitesConfigInternalRecord;
  update: SitesConfigInternalRecord;
  delete?: Maybe<Scalars['Boolean']>;
  materialize?: Maybe<Scalars['Boolean']>;
  materializeDatasource?: Maybe<Scalars['Boolean']>;
  softDelete: SitesConfigInternalRecord;
  fork: SitesConfigInternalRecord;
  copy: SitesConfigInternalRecord;
  redirectAdd: SitesConfigInternalRecord;
  redirectSet: SitesConfigInternalRecord;
  redirectRem: SitesConfigInternalRecord;
  searchOverrideAdd: SitesConfigInternalRecord;
  searchOverrideSet: SitesConfigInternalRecord;
  searchOverrideRem: SitesConfigInternalRecord;
  feedAdd: SitesConfigInternalRecord;
  feedSet: SitesConfigInternalRecord;
  feedRem: SitesConfigInternalRecord;
  queryAdd: SitesConfigInternalRecord;
  querySet: SitesConfigInternalRecord;
  queryRem: SitesConfigInternalRecord;
  pagePromote: SitesPageInternalRecord;
  setParentLink: SitesConfigInternalRecord;
  setLookups: SitesConfigInternalRecord;
};


export type SitesConfigMutationRenameArgs = {
  newName: Scalars['String'];
};


export type SitesConfigMutationUpdateArgs = {
  payload: Scalars['JSON'];
};


export type SitesConfigMutationMaterializeDatasourceArgs = {
  queryId: Scalars['ID'];
};


export type SitesConfigMutationForkArgs = {
  newName: Scalars['String'];
};


export type SitesConfigMutationCopyArgs = {
  newName: Scalars['String'];
};


export type SitesConfigMutationRedirectAddArgs = {
  redirect: SitesRedirectInput;
};


export type SitesConfigMutationRedirectSetArgs = {
  redirectId: Scalars['ID'];
  redirect: SitesRedirectInput;
};


export type SitesConfigMutationRedirectRemArgs = {
  redirectId: Scalars['ID'];
};


export type SitesConfigMutationSearchOverrideAddArgs = {
  searchOverride: SitesSearchOverrideInput;
};


export type SitesConfigMutationSearchOverrideSetArgs = {
  searchOverrideId: Scalars['ID'];
  searchOverride: SitesSearchOverrideInput;
};


export type SitesConfigMutationSearchOverrideRemArgs = {
  searchOverrideId: Scalars['ID'];
};


export type SitesConfigMutationFeedAddArgs = {
  feed: SitesFeedInput;
};


export type SitesConfigMutationFeedSetArgs = {
  feedId: Scalars['ID'];
  feed: SitesFeedInput;
};


export type SitesConfigMutationFeedRemArgs = {
  feedId: Scalars['ID'];
};


export type SitesConfigMutationQueryAddArgs = {
  query: SitesQueryDefinitionInput;
};


export type SitesConfigMutationQuerySetArgs = {
  queryId: Scalars['ID'];
  query: SitesQueryDefinitionInput;
};


export type SitesConfigMutationQueryRemArgs = {
  queryId: Scalars['ID'];
};


export type SitesConfigMutationPagePromoteArgs = {
  pageName: Scalars['String'];
};


export type SitesConfigMutationSetParentLinkArgs = {
  parentLink: Scalars['String'];
};


export type SitesConfigMutationSetLookupsArgs = {
  lookups: Array<Scalars['String']>;
};

export type SitesConfigMutations = {
  create: SitesConfigInternalRecord;
  byId: SitesConfigMutation;
};


export type SitesConfigMutationsCreateArgs = {
  payload: SitesConfigCreateInput;
};


export type SitesConfigMutationsByIdArgs = {
  configId: Scalars['ID'];
};

export type SitesConfigOverrideInput = {
  parentId: Scalars['String'];
  name: Scalars['String'];
  facets: Array<Scalars['String']>;
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  disabled: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type SitesConfigOverrideMutation = {
  update: SitesConfigOverrideRecord;
  copy: SitesConfigOverrideRecord;
  setConfig: SitesConfigOverrideRecord;
  redirectAdd: SitesConfigOverrideRecord;
  redirectSet: SitesConfigOverrideRecord;
  redirectRem: SitesConfigOverrideRecord;
  searchOverrideAdd: SitesConfigOverrideRecord;
  searchOverrideSet: SitesConfigOverrideRecord;
  searchOverrideRem: SitesConfigOverrideRecord;
  delete?: Maybe<Scalars['Boolean']>;
};


export type SitesConfigOverrideMutationUpdateArgs = {
  payload: SitesConfigOverrideInput;
};


export type SitesConfigOverrideMutationCopyArgs = {
  newName: Scalars['String'];
};


export type SitesConfigOverrideMutationSetConfigArgs = {
  payload: Scalars['JSON'];
};


export type SitesConfigOverrideMutationRedirectAddArgs = {
  redirect: SitesRedirectInput;
};


export type SitesConfigOverrideMutationRedirectSetArgs = {
  redirectId: Scalars['ID'];
  redirect: SitesRedirectInput;
};


export type SitesConfigOverrideMutationRedirectRemArgs = {
  redirectId: Scalars['ID'];
};


export type SitesConfigOverrideMutationSearchOverrideAddArgs = {
  searchOverride: SitesSearchOverrideInput;
};


export type SitesConfigOverrideMutationSearchOverrideSetArgs = {
  searchOverrideId: Scalars['ID'];
  searchOverride: SitesSearchOverrideInput;
};


export type SitesConfigOverrideMutationSearchOverrideRemArgs = {
  searchOverrideId: Scalars['ID'];
};

export type SitesConfigOverrideMutations = {
  create: SitesConfigOverrideRecord;
  find: SitesConfigOverrideMutation;
};


export type SitesConfigOverrideMutationsCreateArgs = {
  payload: SitesConfigOverrideInput;
};


export type SitesConfigOverrideMutationsFindArgs = {
  id: Scalars['ID'];
};

export type SitesConfigOverrideRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  parentId: Scalars['String'];
  name: Scalars['String'];
  facets: Array<Scalars['String']>;
  userConfig: Scalars['JSON'];
  redirects: Array<SitesRedirect>;
  searchOverrides: Array<SitesSearchOverride>;
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  disabled: Scalars['Boolean'];
  reason: Scalars['String'];
  parent?: Maybe<SitesConfigInternalRecord>;
};

export type SitesConfigOverrideResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesConfigOverrideRecord>;
};

export type SitesConfigOverrides = {
  filter: SitesConfigOverrideResults;
  find?: Maybe<SitesConfigOverrideRecord>;
  findMany: Array<SitesConfigOverrideRecord>;
};


export type SitesConfigOverridesFilterArgs = {
  filter: SitesOverrideFilterInput;
  paging?: Maybe<SitesPageInput>;
};


export type SitesConfigOverridesFindArgs = {
  id: Scalars['ID'];
};


export type SitesConfigOverridesFindManyArgs = {
  ids: Array<Scalars['ID']>;
};

export type SitesConfigRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  name: Scalars['String'];
  indexId: Scalars['String'];
  /** This is a bucket:id e.g. ref://sites.indexes/203010002 */
  parentLink: Scalars['String'];
  template: Scalars['String'];
  redirects: Array<SitesRedirect>;
  searchOverrides: Array<SitesSearchOverride>;
  queries: Array<SitesQueryDefinition>;
  feeds: Array<SitesFeed>;
  userConfig: Scalars['JSON'];
  configChunk?: Maybe<Scalars['JSON']>;
  adPositions: Array<SitesAdPositionRecord>;
  feed?: Maybe<SitesFeedResponse>;
  routes: Array<SitesPageRoute>;
  page?: Maybe<SitesPageRecord>;
  pages: Array<SitesPageRecord>;
  isDeleted: Scalars['Boolean'];
  /** Current status of materialization. */
  taskStatus?: Maybe<SitesTaskStatus>;
  common?: Maybe<SitesCommonsConfig>;
  sections?: Maybe<SitesConfigTemplateUnion>;
  redirect?: Maybe<SitesRedirectResponse>;
};


export type SitesConfigRecordUserConfigArgs = {
  glob?: Maybe<Scalars['String']>;
};


export type SitesConfigRecordConfigChunkArgs = {
  chunkId: Scalars['String'];
};


export type SitesConfigRecordFeedArgs = {
  params: SitesFeedResumeInput;
};


export type SitesConfigRecordPageArgs = {
  name: Scalars['String'];
};


export type SitesConfigRecordRedirectArgs = {
  path: Scalars['String'];
};

export type SitesConfigs = {
  filter: SitesConfigInternalResults;
  byId?: Maybe<SitesConfigInternalRecord>;
  byIds: Array<SitesConfigInternalRecord>;
  byName?: Maybe<SitesConfigInternalRecord>;
};


export type SitesConfigsFilterArgs = {
  filter: SitesConfigFilterInput;
  paging?: Maybe<SitesPageInput>;
};


export type SitesConfigsByIdArgs = {
  configId: Scalars['ID'];
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesConfigsByIdsArgs = {
  configIds: Array<Scalars['ID']>;
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesConfigsByNameArgs = {
  indexId: Scalars['ID'];
  name: Scalars['String'];
};

export type SitesConfigTemplateUnion = SitesTemplateCoastConfig | SitesTemplateInfernoMicrosite | SitesTemplateInfernoSite | SitesTemplateLocalMicrosite | SitesTemplateLocalSite | SitesTemplateOnairEnrichment | SitesTemplatePremiereSite;

export type SitesContactCommon = {
  requestPhone?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  businessPhone?: Maybe<Scalars['String']>;
  techEmail?: Maybe<Scalars['String']>;
};

export type SitesContactSection = {
  name: Scalars['Boolean'];
  businessPhoneNumber?: Maybe<Scalars['String']>;
  locationAddress1?: Maybe<Scalars['String']>;
  locationAddress2?: Maybe<Scalars['String']>;
  locationCity?: Maybe<Scalars['String']>;
  locationState?: Maybe<Scalars['String']>;
  locationZip?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  requestPhoneNumber?: Maybe<Scalars['String']>;
  smsNumber?: Maybe<Scalars['String']>;
  techContact?: Maybe<Scalars['String']>;
  txtNumber?: Maybe<Scalars['String']>;
};

export type SitesContentCommon = {
  topics?: Maybe<Array<TagRef>>;
  distribution?: Maybe<Array<TagRef>>;
  podcasts?: Maybe<Array<PodcastRef>>;
  playlists?: Maybe<Array<PlaylistRef>>;
  shows?: Maybe<Array<SiteRef>>;
};

export type SitesDatasourceRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  datasourceId: Scalars['ID'];
  configId: Scalars['ID'];
  queryId: Scalars['String'];
  hash: Scalars['String'];
  materialized: Scalars['Boolean'];
};

export type SitesDesignCommon = {
  theme?: Maybe<Scalars['String']>;
  heroColor?: Maybe<Scalars['String']>;
  heroImage?: Maybe<MediaAssetRef>;
  accentColor?: Maybe<Scalars['String']>;
};

export type SitesDesignSection = {
  accentColor?: Maybe<Scalars['String']>;
  ihrHeroColor?: Maybe<Scalars['String']>;
  ihrHeroImage?: Maybe<Scalars['String']>;
  linkColor?: Maybe<Scalars['String']>;
  navBgColor?: Maybe<Scalars['String']>;
  navIhrLinkColor?: Maybe<Scalars['String']>;
  navTextColor?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
  heroFallbackColor?: Maybe<Scalars['String']>;
  heroImage?: Maybe<Scalars['String']>;
};

export type SitesDestinationInput = {
  type: SitesDestinationType;
  value: Scalars['String'];
};

export enum SitesDestinationType {
  Url = 'URL',
  Brand = 'BRAND'
}

export type SitesDistribution = {
  tags: Array<Scalars['String']>;
  tagsData: Array<TagRef>;
};

export type SitesDocumentMetadata = {
  _id: Scalars['String'];
  annotations: Scalars['JSON'];
  base_id: Scalars['String'];
  bucket: Scalars['String'];
  ctime: Scalars['Float'];
  cuser: Scalars['String'];
  deleted: Scalars['Boolean'];
  mimetype?: Maybe<Scalars['String']>;
  mtime: Scalars['Float'];
  muser: Scalars['String'];
  parent_id?: Maybe<Scalars['String']>;
  prefix: Scalars['String'];
  ref_map?: Maybe<Scalars['JSON']>;
  refs: Array<Scalars['String']>;
  schema: Scalars['String'];
  uri?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  member_of: Array<Scalars['String']>;
};

export type SitesFbiaIntegration = SitesIntegrationPlugin & {
  type: Scalars['String'];
  data: Scalars['JSON'];
  plugin: SitesFbiaIntegrationData;
};

export type SitesFbiaIntegrationData = {
  facebook_page_id: Scalars['String'];
  facebook_page_token: Scalars['String'];
  facebook_access_token?: Maybe<Scalars['String']>;
  facebook_published?: Maybe<Scalars['Boolean']>;
};

export type SitesFeed = {
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
  name: Scalars['String'];
  usage?: Maybe<Scalars['String']>;
  repeatedIndex?: Maybe<Scalars['Int']>;
  entries: Array<SitesFeedEntry>;
};

export type SitesFeedBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
  feed?: Maybe<SitesFeedResponse>;
};


export type SitesFeedBlockFeedArgs = {
  scopes?: Maybe<Array<Scalars['String']>>;
  context?: Maybe<Scalars['JSON']>;
};

export type SitesFeedEntry = {
  queryId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type SitesFeedEntryInput = {
  queryId: Scalars['String'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type SitesFeedInput = {
  disabled: Scalars['Boolean'];
  name: Scalars['String'];
  usage?: Maybe<Scalars['String']>;
  repeatedIndex?: Maybe<Scalars['Int']>;
  entries: Array<SitesFeedEntryInput>;
};

export type SitesFeedInspection = {
  queryId: Scalars['String'];
  size: Scalars['Int'];
  skip: Scalars['Int'];
  params: Scalars['JSON'];
};

export type SitesFeedLeadsResult = SitesFeedResult & {
  id: Scalars['ID'];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars['JSON'];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesLeadsCard;
};

export type SitesFeedPubsubResult = SitesFeedResult & {
  id: Scalars['ID'];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars['JSON'];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesPublishRecord;
};

export type SitesFeedPwsResult = SitesFeedResult & {
  id: Scalars['ID'];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars['JSON'];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  record: SitesPwsRecord;
};

export type SitesFeedResponse = {
  results: Array<SitesFeedResult>;
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
  resolvedQueries: Array<SitesQueryDefinition>;
  inspect: Array<SitesFeedInspection>;
  index: SitesIndexRecord;
  config: SitesConfigRecord;
};

export type SitesFeedResult = {
  id: Scalars['ID'];
  type: SitesQueryDatasource;
  /** @deprecated This is a union type; spread on a more specific type and use `record` to select properties. */
  data: Scalars['JSON'];
  /**
   * The result of this resume is a continuation object that can be passed into
   * the `params` input on the `ConfigRecord.feed` resolver. Select all
   * properties and pass the resulting object back in to resume the feed
   * starting with the next result.
   */
  resume?: Maybe<SitesFeedResume>;
};

export type SitesFeedResume = {
  id: Scalars['String'];
  scopes?: Maybe<Array<Scalars['String']>>;
  context?: Maybe<Scalars['JSON']>;
  size?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
};

export type SitesFeedResumeInput = {
  id: Scalars['String'];
  scopes?: Maybe<Array<Scalars['String']>>;
  context?: Maybe<Scalars['JSON']>;
  size?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
};

export type SitesFindManyLookup = {
  type: Sites_Lookup_By;
  value: Scalars['String'];
};

export type SitesIndexBulkMutation = {
  addTags: Array<SitesIndexInternalRecord>;
  remTags: Array<SitesIndexInternalRecord>;
};


export type SitesIndexBulkMutationAddTagsArgs = {
  tags: Array<Scalars['String']>;
};


export type SitesIndexBulkMutationRemTagsArgs = {
  tags: Array<Scalars['String']>;
};

export type SitesIndexCreateInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  parentIndex?: Maybe<Scalars['String']>;
};

export type SitesIndexInternalRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  configLookups: Scalars['JSON'];
  keys: Array<Scalars['String']>;
  references: Array<Scalars['String']>;
  summary: SitesIndexSummary;
  parentIndex: Scalars['ID'];
  ownBrand: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  ownership?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  slug: Scalars['String'];
  hostnames: Array<Scalars['String']>;
  canonicalHostname: Scalars['String'];
  facets: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  status: SitesSiteStatus;
  isDistributionSecured?: Maybe<Scalars['Boolean']>;
  integrations: SitesIntegrations;
  /** Get config by lookup using the lookup map on the IndexInternalRecord, which is either a template id or tag. */
  configByLookup?: Maybe<SitesConfigInternalRecord>;
  stack: Array<SitesIndexInternalRecord>;
  merged?: Maybe<SitesIndexRecord>;
};


export type SitesIndexInternalRecordConfigByLookupArgs = {
  lookup: Scalars['String'];
};

export type SitesIndexInternalResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesIndexInternalRecord>;
};

export type SitesIndexMutation = {
  setSummary: SitesIndexInternalRecord;
  setSlug: SitesIndexInternalRecord;
  remSlug: SitesIndexInternalRecord;
  setBrand: SitesIndexInternalRecord;
  remBrand: SitesIndexInternalRecord;
  setMarket: SitesIndexInternalRecord;
  setTimeZone: SitesIndexInternalRecord;
  setOwnership: SitesIndexInternalRecord;
  setCountry: SitesIndexInternalRecord;
  setDistributionSecured: SitesIndexInternalRecord;
  addTags: SitesIndexInternalRecord;
  remTags: SitesIndexInternalRecord;
  setActive: SitesIndexInternalRecord;
  setInactive: SitesIndexInternalRecord;
  hide: SitesIndexInternalRecord;
  unhide: SitesIndexInternalRecord;
  setParent: SitesIndexInternalRecord;
  addHostname: SitesIndexInternalRecord;
  remHostname: SitesIndexInternalRecord;
  setCanonical: SitesIndexInternalRecord;
  setIntegration: SitesIndexInternalRecord;
  remIntegration: SitesIndexInternalRecord;
  setAsParentOf: SitesIndexInternalRecord;
  remAsParentOf: SitesIndexInternalRecord;
  setAsChildOf: SitesIndexInternalRecord;
  remAsChildOf: SitesIndexInternalRecord;
  setAsPrimaryOf: SitesIndexInternalRecord;
  remAsPrimaryOf: SitesIndexInternalRecord;
  setConfigLookups: SitesIndexInternalRecord;
  delete?: Maybe<Scalars['Boolean']>;
  purgeOnAir?: Maybe<Scalars['Boolean']>;
  materialize?: Maybe<Scalars['Boolean']>;
};


export type SitesIndexMutationSetSummaryArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
};


export type SitesIndexMutationSetSlugArgs = {
  newSlug: Scalars['String'];
};


export type SitesIndexMutationRemSlugArgs = {
  slug: Scalars['String'];
};


export type SitesIndexMutationSetBrandArgs = {
  newBrand: Scalars['String'];
};


export type SitesIndexMutationRemBrandArgs = {
  brand: Scalars['String'];
};


export type SitesIndexMutationSetMarketArgs = {
  newMarket: Scalars['String'];
};


export type SitesIndexMutationSetTimeZoneArgs = {
  newTimeZone: Scalars['String'];
};


export type SitesIndexMutationSetOwnershipArgs = {
  newOwnership?: Maybe<Scalars['String']>;
};


export type SitesIndexMutationSetCountryArgs = {
  newCountry?: Maybe<Scalars['String']>;
};


export type SitesIndexMutationSetDistributionSecuredArgs = {
  isSecured: Scalars['Boolean'];
};


export type SitesIndexMutationAddTagsArgs = {
  tags: Array<Scalars['String']>;
};


export type SitesIndexMutationRemTagsArgs = {
  tags: Array<Scalars['String']>;
};


export type SitesIndexMutationHideArgs = {
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  redirectTo: Scalars['String'];
};


export type SitesIndexMutationSetParentArgs = {
  parentId?: Maybe<Scalars['ID']>;
};


export type SitesIndexMutationAddHostnameArgs = {
  hostname: Scalars['String'];
  canonical?: Maybe<Scalars['Boolean']>;
};


export type SitesIndexMutationRemHostnameArgs = {
  hostname: Scalars['String'];
};


export type SitesIndexMutationSetCanonicalArgs = {
  hostname: Scalars['String'];
};


export type SitesIndexMutationSetIntegrationArgs = {
  plugin: SitesIntegrationPluginInput;
};


export type SitesIndexMutationRemIntegrationArgs = {
  type: Scalars['String'];
};


export type SitesIndexMutationSetAsParentOfArgs = {
  childIndexId: Scalars['ID'];
};


export type SitesIndexMutationRemAsParentOfArgs = {
  childIndexId: Scalars['ID'];
};


export type SitesIndexMutationSetAsChildOfArgs = {
  parentIndexId: Scalars['ID'];
};


export type SitesIndexMutationRemAsChildOfArgs = {
  parentIndexId: Scalars['ID'];
};


export type SitesIndexMutationSetAsPrimaryOfArgs = {
  childIndexId: Scalars['ID'];
};


export type SitesIndexMutationRemAsPrimaryOfArgs = {
  childIndexId: Scalars['ID'];
};


export type SitesIndexMutationSetConfigLookupsArgs = {
  lookups: Array<Scalars['String']>;
  configId: Scalars['ID'];
};

export type SitesIndexRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  configLookups: Scalars['JSON'];
  /**
   * This is a general purpose String! array that contains semantic data. It's
   * the same as the `references` field except that there is a unique database
   * index on it. So this is good for keeping some integrity in the data for
   * unique keys applied to different sites.
   * 
   * This field stores things like slugs, hostnames and other uniquely linked
   * data points.
   */
  keys: Array<Scalars['String']>;
  /**
   * This is a general purpose String! array that contains semantic data. It's
   * a lot like `keys` except that there is an index on this field that is
   * __not__ unique. This is great for tagging relationships between sites
   * that aren't necesarily unique.
   * Right now this replaces the old array of linked microsites thing.
   */
  references: Array<Scalars['String']>;
  summary: SitesIndexSummary;
  parentIndex: Scalars['ID'];
  ownBrand: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  ownership?: Maybe<TagRef>;
  country?: Maybe<TagRef>;
  market: Scalars['String'];
  slug: Scalars['String'];
  hostnames: Array<Scalars['String']>;
  canonicalHostname: Scalars['String'];
  facets: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  status: SitesSiteStatus;
  isDistributionSecured?: Maybe<Scalars['Boolean']>;
  integration?: Maybe<SitesIntegrationPlugin>;
  integrations: SitesIntegrations;
  /** Get config by it's name. */
  configByName?: Maybe<SitesConfigRecord>;
  /** Get config by lookup using the lookup map on the IndexRecord, which is either a template id or tag. */
  configByLookup?: Maybe<SitesConfigRecord>;
  /** Shorthand for the `configByLookup` resolver using the 'site-config-lookups/live' lookup. */
  liveConfig?: Maybe<SitesConfigRecord>;
  onAir: Array<SitesOnAirRecord>;
  onAirSchedule: SitesOnAirScheduleResponse;
  onAirScheduleForDay: SitesOnAirDayScheduleResponse;
  /**
   * Check all indexes in site manager to see if this Site or a parent site
   * is referenced by the type indicated in the `by` argument. Includes references
   * from parent sites.
   * 
   * If the referencing sites have a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   * @deprecated Non-paginated, use `inboundRefs2`
   */
  inboundRefs: Array<SitesIndexRecord>;
  /**
   * Check all indexes in site manager to see if this Site or a parent site
   * is referenced by the type indicated in the `by` argument. Includes references
   * from parent sites.
   * 
   * If the referencing sites have a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundRefs2: SitesIndexResults;
  /**
   * Look up a site using the specified `type`/`value` lookup and return it if
   * this Site is referenced by the type indicated in the `by` argument.
   * 
   * If the referencing site has an site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundRef?: Maybe<SitesIndexRecord>;
  /**
   * Check my own references and create a dataset based on a particular
   * constraint.
   * 
   * If the referenced site has an site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  outboundRefs: Array<SitesIndexRecord>;
  /**
   * Look in the entire database for my id constrained with `by` argument.
   * 
   * If the inbound site has a site-specific config, this Site will be used to
   * attempt to look it up in the `liveConfig` and `configByLookup` resolvers.
   */
  inboundKeys?: Maybe<SitesIndexRecord>;
  /** Mount point for routes to this site inside a parent site. */
  embedBasePath?: Maybe<Scalars['String']>;
  /** Current status of materialization. */
  taskStatus?: Maybe<SitesTaskStatus>;
  events: Array<CalendarEvent>;
  eventCategories: Array<Scalars['String']>;
  stream?: Maybe<Stream>;
};


export type SitesIndexRecordIntegrationArgs = {
  type: Scalars['String'];
};


export type SitesIndexRecordConfigByNameArgs = {
  name: Scalars['String'];
};


export type SitesIndexRecordConfigByLookupArgs = {
  lookup: Scalars['String'];
};


export type SitesIndexRecordOnAirScheduleArgs = {
  currentDate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
};


export type SitesIndexRecordOnAirScheduleForDayArgs = {
  day: Sites_Onair_Day;
  timeZone?: Maybe<Scalars['String']>;
};


export type SitesIndexRecordInboundRefsArgs = {
  by: Sites_Referenced_By;
};


export type SitesIndexRecordInboundRefs2Args = {
  by: Sites_Referenced_By;
  paging?: Maybe<SitesPageInput>;
};


export type SitesIndexRecordInboundRefArgs = {
  type: Sites_Lookup_By;
  value: Scalars['String'];
  by: Sites_Referenced_By;
};


export type SitesIndexRecordOutboundRefsArgs = {
  by: Sites_Referenced_By;
};


export type SitesIndexRecordInboundKeysArgs = {
  by: Sites_Lookup_By;
};


export type SitesIndexRecordEventsArgs = {
  category?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type SitesIndexRecordEventCategoriesArgs = {
  start_time?: Maybe<Scalars['Float']>;
  end_time?: Maybe<Scalars['Float']>;
};

export type SitesIndexResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesIndexRecord>;
};

export type SitesIndexSummary = {
  name: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
};

export type SitesIntegrationPlugin = {
  type: Scalars['String'];
  data: Scalars['JSON'];
};

export type SitesIntegrationPluginInput = {
  type: Scalars['String'];
  data: Scalars['JSON'];
};

export type SitesIntegrations = {
  plugins: Array<SitesIntegrationPlugin>;
};

export type SitesInternalBlock = {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SitesItemBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
  item?: Maybe<SitesItemResponse>;
};


export type SitesItemBlockItemArgs = {
  scopes?: Maybe<Array<Scalars['String']>>;
  context?: Maybe<Scalars['JSON']>;
};

export type SitesItemResponse = {
  result?: Maybe<SitesFeedResult>;
  resolvedQueries: Array<SitesQueryDefinition>;
  inspect: Array<SitesFeedInspection>;
  index: SitesIndexRecord;
  config: SitesConfigRecord;
};

export type SitesKeywordContestBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
  campaignId: Scalars['String'];
};

export type SitesLeadsCard = {
  id: Scalars['String'];
  img_uri?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  priority: Scalars['Int'];
  important: Scalars['Boolean'];
  range: SitesLeadsCardRange;
  targets: Array<SitesDistribution>;
  exclusion: Array<SitesDistribution>;
  title: Scalars['String'];
  subtitle: Scalars['String'];
  background_color: Scalars['String'];
  catalog?: Maybe<SitesCatalogItem>;
  use_catalog_image?: Maybe<Scalars['Boolean']>;
  link?: Maybe<SitesLeadsCardLink>;
  img_meta?: Maybe<SitesLeadsCardImgMeta>;
};

export type SitesLeadsCardImgMeta = {
  bucket?: Maybe<Scalars['String']>;
  base_id?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  ops: Array<SitesMediaOperation>;
};

export type SitesLeadsCardLink = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  urls?: Maybe<Scalars['JSON']>;
};

export type SitesLeadsCardRange = {
  begin: Scalars['Float'];
  end?: Maybe<Scalars['Float']>;
};

export type SitesLinksData = {
  name: Scalars['String'];
  linksType: SitesLinksType;
  result: SitesQueryOutput;
};

export type SitesLinksInInput = {
  /** Field name to fetch links for. */
  name: Scalars['String'];
  /** Query a particular pubsub type for results. */
  type: Scalars['String'];
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  /** Page size for results. Default: 25 */
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<SitesSortField>;
};

export type SitesLinksOutInput = {
  /** Field name to fetch links for. */
  name: Scalars['String'];
  /** Narrow results to a particular pubsub type. */
  type?: Maybe<Scalars['String']>;
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  /** Which field to sort on. Default: PUB_START */
  sortField?: Maybe<SitesSortField>;
};

export enum SitesLinksType {
  LinksIn = 'LINKS_IN',
  LinksOut = 'LINKS_OUT'
}

export type SitesLocationCommon = {
  locale?: Maybe<TagRef>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  addr1?: Maybe<Scalars['String']>;
  addr2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  /** @deprecated This data has been moved to the `IndexRecord.country` resolver. */
  country?: Maybe<Scalars['String']>;
};

export type SitesMediaOperation = {
  op?: Maybe<Scalars['String']>;
  args: Array<Scalars['String']>;
};

export type SitesMenu = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['String']>;
  children?: Maybe<Array<SitesMenu>>;
};

export type SiteSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  facebook_name?: Maybe<Scalars['String']>;
  facebook_app_id?: Maybe<Scalars['String']>;
  facebook_fan_page_id?: Maybe<Scalars['String']>;
  facebook_api_key?: Maybe<Scalars['String']>;
  facebook_app_secret?: Maybe<Scalars['String']>;
  googleplus_switch?: Maybe<Scalars['Boolean']>;
  googleplus_name?: Maybe<Scalars['String']>;
  googleplus_page_id?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  twitter_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  instagram_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  snapchat_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  youtube_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  pinterest_name?: Maybe<Scalars['String']>;
};

export type SitesOnAir = {
  byId?: Maybe<SitesOnAirRecord>;
  bySiteIds: Array<SitesOnAirRecord>;
  generateSchedule: SitesOnAirScheduleResponse;
  generateScheduleForDay: SitesOnAirDayScheduleResponse;
};


export type SitesOnAirByIdArgs = {
  id: Scalars['ID'];
};


export type SitesOnAirBySiteIdsArgs = {
  siteIds: Array<Scalars['ID']>;
};


export type SitesOnAirGenerateScheduleArgs = {
  siteId: Scalars['ID'];
  currentDate?: Maybe<Scalars['Float']>;
  timeZone?: Maybe<Scalars['String']>;
};


export type SitesOnAirGenerateScheduleForDayArgs = {
  siteId: Scalars['ID'];
  day: Sites_Onair_Day;
  timeZone?: Maybe<Scalars['String']>;
};

export type SitesOnAirDayScheduleResponse = {
  schedule: Array<SitesOnAirExtended>;
  timeZone: Scalars['String'];
};


export type SitesOnAirDayScheduleResponseScheduleArgs = {
  take?: Maybe<Scalars['Int']>;
};

export type SitesOnAirExtended = {
  siteId: Scalars['String'];
  site: Scalars['String'];
  coreShowId?: Maybe<Scalars['Int']>;
  coreStationId?: Maybe<Scalars['Int']>;
  show: Scalars['String'];
  station: Scalars['String'];
  /**
   * This name is copied from CORE data and, if this entry is associated with a
   * site, replaced with that site's display name.
   */
  name: Scalars['String'];
  /**
   * If this entry is associated with a site, this image is populated with that
   * sites' display thumbnail.
   */
  image?: Maybe<MediaAssetRef>;
  /**
   * The site linked to this onair entry via its `shows` tag from CORE. Allows
   * selecting any datapoints pertaining to associated site.
   */
  showSite?: Maybe<SitesIndexRecord>;
  /**
   * Provides some display properties for rendering the destination of an onair
   * entry, if a destination exists. These values are stored on the onair entry
   * itself.
   */
  destination?: Maybe<SitesOnAirExtendedDestination>;
  onNow: Scalars['Boolean'];
  startTime12: Scalars['String'];
  startDate: Scalars['String'];
  startMs: Scalars['Float'];
  startEng: Scalars['String'];
  stopTime12: Scalars['String'];
  stopDate: Scalars['String'];
  stopMs: Scalars['Float'];
  stopEng: Scalars['String'];
  link: Scalars['String'];
};

export type SitesOnAirExtendedDestination = {
  imageRef?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  slug: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated Always `true`. */
  linkedToMicrosite: Scalars['Boolean'];
  href: Scalars['String'];
  link: Scalars['String'];
};

export type SitesOnAirFilterInput = {
  indexIds?: Maybe<Array<Scalars['ID']>>;
};

export type SitesOnAirInput = {
  siteId: Scalars['String'];
  site: Scalars['String'];
  coreShowId?: Maybe<Scalars['Int']>;
  coreStationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  personality: Scalars['String'];
  show: Scalars['String'];
  station: Scalars['String'];
  link: Scalars['String'];
  source: Sites_Onair_Source;
  schedule: SitesOnAirScheduleInput;
};

export type SitesOnAirMutation = {
  update: SitesOnAirRecord;
  delete?: Maybe<Scalars['Boolean']>;
};


export type SitesOnAirMutationUpdateArgs = {
  payload: SitesOnAirInput;
};

export type SitesOnAirMutations = {
  create: SitesOnAirRecord;
  byId: SitesOnAirMutation;
};


export type SitesOnAirMutationsCreateArgs = {
  payload: SitesOnAirInput;
};


export type SitesOnAirMutationsByIdArgs = {
  id: Scalars['ID'];
};

export type SitesOnAirRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  siteId: Scalars['String'];
  site: Scalars['String'];
  coreShowId?: Maybe<Scalars['Int']>;
  coreStationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  image?: Maybe<MediaAssetRef>;
  personality: Scalars['String'];
  show: Scalars['String'];
  station: Scalars['String'];
  link: Scalars['String'];
  schedule: SitesOnAirSchedule;
  source: Sites_Onair_Source;
};

export type SitesOnAirResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesOnAirRecord>;
};

export type SitesOnAirSchedule = {
  su?: Maybe<SitesOnAirTimeBox>;
  mo?: Maybe<SitesOnAirTimeBox>;
  tu?: Maybe<SitesOnAirTimeBox>;
  we?: Maybe<SitesOnAirTimeBox>;
  th?: Maybe<SitesOnAirTimeBox>;
  fr?: Maybe<SitesOnAirTimeBox>;
  sa?: Maybe<SitesOnAirTimeBox>;
};

export type SitesOnAirScheduleInput = {
  su?: Maybe<SitesOnAirTimeBoxInput>;
  mo?: Maybe<SitesOnAirTimeBoxInput>;
  tu?: Maybe<SitesOnAirTimeBoxInput>;
  we?: Maybe<SitesOnAirTimeBoxInput>;
  th?: Maybe<SitesOnAirTimeBoxInput>;
  fr?: Maybe<SitesOnAirTimeBoxInput>;
  sa?: Maybe<SitesOnAirTimeBoxInput>;
};

export type SitesOnAirScheduleResponse = {
  current?: Maybe<SitesOnAirExtended>;
  upcoming: Array<SitesOnAirExtended>;
  timeZone: Scalars['String'];
};


export type SitesOnAirScheduleResponseUpcomingArgs = {
  take?: Maybe<Scalars['Int']>;
  includeCurrent?: Maybe<Scalars['Boolean']>;
};

export type SitesOnAirTimeBox = {
  start: Scalars['String'];
  stop: Scalars['String'];
};

export type SitesOnAirTimeBoxInput = {
  start: Scalars['String'];
  stop: Scalars['String'];
};

export type SitesOverrideFilterInput = {
  startDate?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  facets?: Maybe<Array<Scalars['String']>>;
};

export type SitesPageBlock = {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SitesPagedResult = {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
};

export type SitesPagedResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
};

export type SitesPageInfo = {
  nextCursor?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export type SitesPageInput = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  nextCursor?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export type SitesPageInternalRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  /** @deprecated Field no longer supported */
  parentId?: Maybe<Scalars['String']>;
  configId: Scalars['String'];
  name: Scalars['String'];
  layoutId: Scalars['String'];
  displayName: Scalars['String'];
  path: Scalars['String'];
  /** @deprecated Parse path vars out of `path`. */
  pathVariables: Scalars['JSON'];
  /** @deprecated Parse blocks for block tagged with 'display-hints/primary'. */
  primaryBlockId: Scalars['String'];
  pageConfig: Scalars['JSON'];
  tags: Array<TagRef>;
  blocks: Array<SitesInternalBlock>;
  stack: Array<SitesPageInternalRecord>;
};

export type SitesPageMutation = {
  setConfig: SitesPageInternalRecord;
  setDisplayName: SitesPageInternalRecord;
  setPath: SitesPageInternalRecord;
  setTags: SitesPageInternalRecord;
  setVariables: SitesPageInternalRecord;
  blockAdd: SitesPageInternalRecord;
  blockSet: SitesPageInternalRecord;
  blockRem: SitesPageInternalRecord;
  blockSetOrdinal: SitesPageInternalRecord;
  delete?: Maybe<Scalars['Boolean']>;
};


export type SitesPageMutationSetConfigArgs = {
  data: Scalars['JSON'];
};


export type SitesPageMutationSetDisplayNameArgs = {
  newName: Scalars['String'];
};


export type SitesPageMutationSetPathArgs = {
  newPath: Scalars['String'];
};


export type SitesPageMutationSetTagsArgs = {
  tags: Array<Scalars['String']>;
};


export type SitesPageMutationSetVariablesArgs = {
  variables: Scalars['JSON'];
};


export type SitesPageMutationBlockAddArgs = {
  block: SitesBlockInput;
};


export type SitesPageMutationBlockSetArgs = {
  blockId: Scalars['String'];
  block: SitesBlockInput;
};


export type SitesPageMutationBlockRemArgs = {
  blockId: Scalars['String'];
};


export type SitesPageMutationBlockSetOrdinalArgs = {
  blockId: Scalars['String'];
  ordinal: Scalars['Int'];
};

export type SitesPageMutations = {
  create: SitesPageInternalRecord;
  byId: SitesPageMutation;
};


export type SitesPageMutationsCreateArgs = {
  page: SitesAddPageInput;
};


export type SitesPageMutationsByIdArgs = {
  pageId: Scalars['ID'];
};

export type SitesPageOverrideInput = {
  parentId: Scalars['String'];
  name: Scalars['String'];
  facets: Array<Scalars['String']>;
  pages: Array<Scalars['String']>;
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  disabled: Scalars['Boolean'];
  reason: Scalars['String'];
  blocks: Array<SitesBlockOverrideInput>;
};

export type SitesPageOverrideMutation = {
  update: SitesPageOverrideRecord;
  copy: SitesPageOverrideRecord;
  delete?: Maybe<Scalars['Boolean']>;
};


export type SitesPageOverrideMutationUpdateArgs = {
  payload: SitesPageOverrideInput;
};


export type SitesPageOverrideMutationCopyArgs = {
  newName: Scalars['String'];
};

export type SitesPageOverrideMutations = {
  create: SitesPageOverrideRecord;
  find: SitesPageOverrideMutation;
};


export type SitesPageOverrideMutationsCreateArgs = {
  payload: SitesPageOverrideInput;
};


export type SitesPageOverrideMutationsFindArgs = {
  id: Scalars['ID'];
};

export type SitesPageOverrideRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  parentId: Scalars['String'];
  name: Scalars['String'];
  facets: Array<Scalars['String']>;
  pages: Array<Scalars['String']>;
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  disabled: Scalars['Boolean'];
  reason: Scalars['String'];
  blocks: Array<SitesBlockOverride>;
  parent?: Maybe<SitesConfigInternalRecord>;
};

export type SitesPageOverrideResults = SitesPagedResult & {
  count: Scalars['Int'];
  pageInfo: SitesPageInfo;
  results: Array<SitesPageOverrideRecord>;
};

export type SitesPageOverrides = {
  filter: SitesPageOverrideResults;
  find?: Maybe<SitesPageOverrideRecord>;
  findMany: Array<SitesPageOverrideRecord>;
};


export type SitesPageOverridesFilterArgs = {
  filter: SitesOverrideFilterInput;
  paging?: Maybe<SitesPageInput>;
};


export type SitesPageOverridesFindArgs = {
  id: Scalars['ID'];
};


export type SitesPageOverridesFindManyArgs = {
  ids: Array<Scalars['ID']>;
};

export type SitesPageRecord = {
  _id: Scalars['ID'];
  _meta: SitesDocumentMetadata;
  configId: Scalars['String'];
  name: Scalars['String'];
  /** @deprecated Field no longer supported */
  parentId?: Maybe<Scalars['String']>;
  layoutId: Scalars['String'];
  displayName: Scalars['String'];
  path: Scalars['String'];
  /** @deprecated Parse path vars out of `path`. */
  pathVariables: Scalars['JSON'];
  /** @deprecated Parse blocks for block tagged with 'display-hints/primary'. */
  primaryBlockId: Scalars['String'];
  pageConfig: Scalars['JSON'];
  tags: Array<TagRef>;
  block?: Maybe<SitesPageBlock>;
  blocks: Array<SitesPageBlock>;
  resolvedPath?: Maybe<Scalars['String']>;
};


export type SitesPageRecordBlockArgs = {
  blockId: Scalars['String'];
};


export type SitesPageRecordResolvedPathArgs = {
  params?: Maybe<Scalars['JSON']>;
};

export type SitesPageRoute = {
  name: Scalars['String'];
  path: Scalars['String'];
};

export type SitesPages = {
  byId?: Maybe<SitesPageInternalRecord>;
  byConfig: Array<SitesPageInternalRecord>;
  byName?: Maybe<SitesPageInternalRecord>;
};


export type SitesPagesByIdArgs = {
  pageId: Scalars['ID'];
};


export type SitesPagesByConfigArgs = {
  configId: Scalars['ID'];
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesPagesByNameArgs = {
  configId: Scalars['ID'];
  name: Scalars['String'];
};

export type SitesPartnersCommon = {
  showIniHeartSwitch?: Maybe<Scalars['Boolean']>;
  postupSwitch?: Maybe<Scalars['Boolean']>;
  emailFormat?: Maybe<TagRef>;
};

export type SitesPartnersSection = {
  emailFormat?: Maybe<Scalars['String']>;
  ihrShowInIheartSwitch?: Maybe<Scalars['Boolean']>;
  postupSwitch?: Maybe<Scalars['Boolean']>;
};

export type SitesPodcastsBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
  podcasts: Array<Scalars['String']>;
  podcastRefs: Array<PodcastRef>;
};

export type SitesPublishRecord = {
  id?: Maybe<Scalars['ID']>;
  ref_id: Scalars['ID'];
  slug: Scalars['String'];
  type: Scalars['String'];
  summary: SitesPublishSummary;
  payload?: Maybe<Scalars['JSON']>;
  subscription: Array<SitesDistribution>;
  exclusion: Array<SitesDistribution>;
  pub_start: Scalars['Float'];
  pub_until?: Maybe<Scalars['Float']>;
  pub_changed?: Maybe<Scalars['Float']>;
  pub_touched: Scalars['Float'];
  /** @deprecated use linksIn, linksOut */
  links: SitesQueryOutput;
  /** Fetch content which links to this record. */
  linksIn: SitesQueryOutput;
  /** Fetch content this record links to. */
  linksOut: Array<SitesPublishRecord>;
  keys: Array<Scalars['String']>;
  linksData: Array<SitesLinksData>;
  canonicalUrl?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
};


export type SitesPublishRecordLinksArgs = {
  pub_start?: Maybe<Scalars['Float']>;
  pub_until?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Int']>;
  link_type?: Maybe<Scalars['String']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type SitesPublishRecordLinksInArgs = {
  query: SitesLinksInInput;
};


export type SitesPublishRecordLinksOutArgs = {
  query: SitesLinksOutInput;
};

export type SitesPublishSummary = {
  author: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SitesPwsGuest = {
  id: Scalars['String'];
  pubStart: DateRef;
  slug: Scalars['String'];
  summary: SitesPublishSummary;
};

export type SitesPwsRecord = SitesPwsGuest;

export enum SitesQueryDatasource {
  Publishing = 'PUBLISHING',
  Leads = 'LEADS',
  Pws = 'PWS'
}

export type SitesQueryDefinition = {
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
  source: SitesQueryDatasource;
  name: Scalars['String'];
  spec: Scalars['JSON'];
  defaultVariables: Scalars['JSON'];
};

export type SitesQueryDefinitionInput = {
  disabled: Scalars['Boolean'];
  source: SitesQueryDatasource;
  name: Scalars['String'];
  spec: Scalars['JSON'];
  defaultVariables: Scalars['JSON'];
};

export type SitesQueryOutput = {
  items: Array<SitesPublishRecord>;
  query: Scalars['JSON'];
  has_more: Scalars['Boolean'];
  next?: Maybe<Scalars['String']>;
};

export type SitesRedirect = {
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
  source: Scalars['String'];
  destination: SitesRedirectDest;
  schedule?: Maybe<SitesSchedule>;
  permanent: Scalars['Boolean'];
  regex: Scalars['Boolean'];
  priority: Scalars['Int'];
  matchQueryString: Scalars['Boolean'];
  queryParamsHandling: SitesRedirectQueryParamsHandling;
};

export type SitesRedirectDest = {
  type: SitesDestinationType;
  value: Scalars['String'];
};

export type SitesRedirectInput = {
  disabled: Scalars['Boolean'];
  source: Scalars['String'];
  destination: SitesDestinationInput;
  schedule?: Maybe<SitesScheduleInput>;
  permanent: Scalars['Boolean'];
  regex: Scalars['Boolean'];
  priority: Scalars['Int'];
  matchQueryString: Scalars['Boolean'];
  queryParamsHandling: SitesRedirectQueryParamsHandling;
};

export enum SitesRedirectQueryParamsHandling {
  Original = 'ORIGINAL',
  Merge = 'MERGE',
  Discard = 'DISCARD'
}

export type SitesRedirectResponse = {
  destination: Scalars['String'];
  permanent: Scalars['Boolean'];
};

export type SitesSchedule = {
  begin: Scalars['Float'];
  end: Scalars['Float'];
};

export type SitesScheduleInput = {
  begin: Scalars['Float'];
  end: Scalars['Float'];
};

export type SitesSearchOverride = {
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
  keywords: Array<Scalars['String']>;
  destination: SitesRedirectDest;
  schedule?: Maybe<SitesSchedule>;
};

export type SitesSearchOverrideInput = {
  disabled: Scalars['Boolean'];
  keywords: Array<Scalars['String']>;
  destination: SitesDestinationInput;
  schedule?: Maybe<SitesScheduleInput>;
};

export type SitesShowBlock = SitesPageBlock & {
  id: Scalars['String'];
  type: Scalars['String'];
  ordinal: Scalars['Int'];
  region: Scalars['String'];
  value: Scalars['JSON'];
  disabled: Scalars['Boolean'];
  schedule?: Maybe<SitesSchedule>;
  tags?: Maybe<Array<Scalars['String']>>;
  shows: Array<SitesIndexRecord>;
};

export type SitesShowsKeysIntegration = SitesIntegrationPlugin & {
  type: Scalars['String'];
  data: Scalars['JSON'];
  plugin: SitesShowsKeysIntegrationData;
};

export type SitesShowsKeysIntegrationData = {
  linked_shows: Array<Scalars['String']>;
};

export type SitesSiteMutations = {
  create: SitesIndexInternalRecord;
  find: SitesIndexMutation;
  findMany: SitesIndexBulkMutation;
};


export type SitesSiteMutationsCreateArgs = {
  payload: SitesIndexCreateInput;
};


export type SitesSiteMutationsFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars['String'];
};


export type SitesSiteMutationsFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
};

export type SitesSites = {
  /** @deprecated Non-paginated, use `filter` */
  search: SitesSitesSearchOutput;
  filter: SitesIndexResults;
  find?: Maybe<SitesIndexRecord>;
  findMany: Array<SitesIndexRecord>;
  /** @deprecated Non-paginated, use `findReferenced2` */
  findReferenced: Array<SitesIndexRecord>;
  findReferenced2: SitesIndexResults;
};


export type SitesSitesSearchArgs = {
  filter: SitesSitesFilterInput;
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesSitesFilterArgs = {
  filter: SitesSitesFilterInput;
  unrestricted?: Maybe<Scalars['Boolean']>;
  paging?: Maybe<SitesPageInput>;
};


export type SitesSitesFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars['String'];
};


export type SitesSitesFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesSitesFindReferencedArgs = {
  type: Sites_Referenced_By;
  value: Scalars['String'];
};


export type SitesSitesFindReferenced2Args = {
  type: Sites_Referenced_By;
  value: Scalars['String'];
  paging?: Maybe<SitesPageInput>;
};

export type SitesSitesFilterInput = {
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  lookups?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  orTags?: Maybe<Scalars['Boolean']>;
  ownership?: Maybe<Array<Scalars['String']>>;
  active?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  template?: Maybe<Scalars['String']>;
};

export type SitesSitesInternal = {
  filter: SitesIndexInternalResults;
  find?: Maybe<SitesIndexInternalRecord>;
  findMany: Array<SitesIndexInternalRecord>;
  findReferenced: SitesIndexInternalResults;
  commonConfigs: Array<SitesCommonConfigRecord>;
};


export type SitesSitesInternalFilterArgs = {
  filter: SitesSitesFilterInput;
  paging?: Maybe<SitesPageInput>;
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesSitesInternalFindArgs = {
  type: Sites_Lookup_By;
  value: Scalars['String'];
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesSitesInternalFindManyArgs = {
  lookups: Array<SitesFindManyLookup>;
  unrestricted?: Maybe<Scalars['Boolean']>;
};


export type SitesSitesInternalFindReferencedArgs = {
  type: Sites_Referenced_By;
  value: Scalars['String'];
  paging?: Maybe<SitesPageInput>;
};


export type SitesSitesInternalCommonConfigsArgs = {
  filter: SitesCommonConfigsInput;
};

export type SitesSitesSearchOutput = {
  count: Scalars['Int'];
  records: Array<SitesIndexRecord>;
};

export type SitesSiteStatus = {
  isActive: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  hide?: Maybe<SitesSiteStatusHide>;
};

export type SitesSiteStatusHide = {
  startDate: Scalars['Float'];
  endDate: Scalars['Float'];
  redirectTo: Scalars['String'];
};

export type SitesSlackIntegration = SitesIntegrationPlugin & {
  type: Scalars['String'];
  data: Scalars['JSON'];
  plugin: SitesSlackIntegrationData;
};

export type SitesSlackIntegrationData = {
  subscriptions: Array<SitesSlackSubscriptionIntegrationData>;
};

export type SitesSlackSubscriptionIntegrationData = {
  channel: Scalars['String'];
  events: Array<Scalars['String']>;
};

export type SitesSocialCommon = {
  facebookName?: Maybe<Scalars['String']>;
  facebookSwitch?: Maybe<Scalars['Boolean']>;
  twitterName?: Maybe<Scalars['String']>;
  twitterSwitch?: Maybe<Scalars['String']>;
  instagramName?: Maybe<Scalars['String']>;
  instagramSwitch?: Maybe<Scalars['String']>;
  youtubeName?: Maybe<Scalars['String']>;
  youtubeSwitch?: Maybe<Scalars['String']>;
  snapchatName?: Maybe<Scalars['String']>;
  snapchatSwitch?: Maybe<Scalars['String']>;
  pinterestName?: Maybe<Scalars['String']>;
  pinterestSwitch?: Maybe<Scalars['String']>;
  tiktokName?: Maybe<Scalars['String']>;
  tiktokSwitch?: Maybe<Scalars['String']>;
};

export type SitesSocialSection = {
  facebookName?: Maybe<Scalars['String']>;
  facebookSwitch?: Maybe<Scalars['Boolean']>;
  instagramName?: Maybe<Scalars['String']>;
  instagramSwitch?: Maybe<Scalars['Boolean']>;
  pinterestName?: Maybe<Scalars['String']>;
  pinterestSwitch?: Maybe<Scalars['Boolean']>;
  snapchatName?: Maybe<Scalars['String']>;
  snapchatSwitch?: Maybe<Scalars['Boolean']>;
  tiktokName?: Maybe<Scalars['String']>;
  tiktokSwitch?: Maybe<Scalars['Boolean']>;
  twitterName?: Maybe<Scalars['String']>;
  twitterSwitch?: Maybe<Scalars['Boolean']>;
  youtubeName?: Maybe<Scalars['String']>;
  youtubeSwitch?: Maybe<Scalars['Boolean']>;
};

export enum SitesSortField {
  PubStart = 'PUB_START',
  PubTouched = 'PUB_TOUCHED'
}

export type SitesStreamKeysIntegration = SitesIntegrationPlugin & {
  type: Scalars['String'];
  data: Scalars['JSON'];
};

export enum SitesTaskStatus {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Error = 'ERROR'
}

export type SitesTemplateCoastConfig = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplateCoastConfigGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplateCoastConfigAlert>;
  navigation?: Maybe<SitesTemplateCoastConfigNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplateCoastConfigDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplateCoastConfigSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplateCoastConfigContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplateCoastConfigPartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplateCoastConfigAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplateCoastConfigAnalytics>;
};

export type SitesTemplateCoastConfigAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars['Boolean']>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars['Boolean']>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars['Boolean']>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars['String']>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars['String']>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars['String']>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplateCoastConfigAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars['String']>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars['Boolean']>;
  poc_primary_analytics_id?: Maybe<Scalars['String']>;
  poc_secondary_analytics_id?: Maybe<Scalars['String']>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars['Boolean']>;
  parsely_account_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars['String']>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars['Boolean']>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars['String']>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars['String']>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars['String']>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars['String']>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars['Boolean']>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars['String']>;
  /** Physical street address */
  location_address1?: Maybe<Scalars['String']>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars['String']>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars['String']>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars['String']>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars['String']>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars['String']>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<SitesTemplateCoastConfigContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars['Boolean']>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars['String']>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars['String']>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars['String']>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars['String']>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum size 316x200px */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  link_color?: Maybe<Scalars['String']>;
  /** Background color for the header at the top of the site */
  nav_bg_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_text_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_ihr_link_color?: Maybe<Scalars['String']>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** Image used at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /** Face color at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_color?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars['String']>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars['String']>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /** Specify content topics for distributing content to this site's homepage */
  default_distribution?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /**
   * Specify this site's primary personalities by selecting their microsite brands.
   *  Used to populate the personality-focused sections of the homepage
   */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars['String']>;
  /**
   * The country this site is licensed to or originates from. This information is
   * sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars['String']>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars['String']>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplateCoastConfigGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines whether text that cannot otherwise be customized on this site shows in English or Spanish */
  language?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars['String']>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars['String']>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars['String']>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars['Boolean']>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the addition of social and contact links in the site header and footer */
  connect_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables links in the site footer for Public Inspection File and Political file in the footer */
  show_public_file_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateCoastConfigPartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars['Boolean']>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<CatalogPointerRef>;
  /** Specify the maximum number of tracks that should be shown on the Top Songs page */
  ihr_playlist_max?: Maybe<Scalars['String']>;
  /** Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations */
  ihr_player_playlist?: Maybe<Scalars['Boolean']>;
  /**
   * Specify whether content shown on this site's iHeart.com profile page opens on
   * iHeart.com (checked) or on the station site (unchecked).
   */
  ihr_show_in_iheart_switch?: Maybe<Scalars['Boolean']>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars['Boolean']>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the Total Traffic SigAlert widget on this site */
  total_traffic_switch?: Maybe<Scalars['Boolean']>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars['String']>;
  /** Zoom level on map, 0 = most zoomed in; 3 = most zoomed out */
  traffic_zoom?: Maybe<Scalars['String']>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars['Boolean']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  alexa_pronouncer?: Maybe<Scalars['String']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  google_pronouncer?: Maybe<Scalars['String']>;
};

export type SitesTemplateCoastConfigSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_api_key?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_secret?: Maybe<Scalars['String']>;
  /** Enables this brand to publish Facebook Instant Articles, requires fan page ID */
  facebook_page_token?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  tiktok_switch?: Maybe<Scalars['Boolean']>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoMicrosite = {
  /** Supply basic information about this microsite's name/description, CORE show, default content, locale, imagery, and podcast */
  general?: Maybe<SitesTemplateInfernoMicrositeGeneral>;
  /** Manage secondary navigation that appearse at the top of every page within this microsite */
  navigation?: Maybe<SitesTemplateInfernoMicrositeNavigation>;
  /** Specify a long-form biography and contact information for this microsite */
  contact?: Maybe<SitesTemplateInfernoMicrositeContact>;
  /** Connect this microsite with various social networks */
  social?: Maybe<SitesTemplateInfernoMicrositeSocial>;
};

export type SitesTemplateInfernoMicrositeContact = {
  full_bio?: Maybe<Scalars['String']>;
  /** Dedicated phone number for this show, general station request lines not recommended */
  phone_number?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the show - legally required disclaimer added automatically */
  txt_number?: Maybe<Scalars['String']>;
  /** Public e-mail address, visible to end users - for multi-market shows, use an @iheartmedia.com or @iheartradio.com address */
  email_address?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoMicrositeGeneral = {
  /** The name of the brand, as shown at the top of the page */
  name?: Maybe<Scalars['String']>;
  /**
   * Used for Google and other search engines and appears at the bottom of
   * microsite pages.  Recommended length 275-300 characters
   */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes.  Also can be used for ad targeting */
  keywords?: Maybe<Array<TagRef>>;
  /**
   * Associate this microsite with one or more CORE shows
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'show-keys')` resolver.
   */
  linked_shows?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics to populate this microsite's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /** Reserved for future use */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  default_playlists?: Maybe<Array<PlaylistRef>>;
  /** 2880x570 top of page image - search 'hero' at digitalsupport.iheart.com for best practices and template */
  hero_image?: Maybe<MediaAssetRef>;
  /** Square image at least 600x600px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Upload a 16x9 image, at least 300px tall */
  sixteen_by_nine?: Maybe<MediaAssetRef>;
  /** Specify the color the hero image fades to on the sides */
  hero_fallback_color?: Maybe<Scalars['String']>;
  /** Enables 3rd party recommendation widgets to appear on this site, if also enabled on parent site */
  recommendations?: Maybe<Scalars['Boolean']>;
  /** Determines whether microsite is visible to end users */
  status?: Maybe<Scalars['String']>;
  /** Outside of these dates, this microsite will not be accessible */
  active_start?: Maybe<DateRef>;
  active_end?: Maybe<DateRef>;
  password_switch?: Maybe<Scalars['Boolean']>;
  /** Contact Digital Support to add/remove password protection */
  password?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoMicrositeNavigation = {
  enable_nav_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateInfernoMicrositeSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of your facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/JohnDoe, put "JohnDoe" here - if at youtube.com/channel/JaneSmith, put "channel/JaneSmith" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  tiktok_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's TikTok username */
  tiktok_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSite = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplateInfernoSiteGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplateInfernoSiteAlert>;
  navigation?: Maybe<SitesTemplateInfernoSiteNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplateInfernoSiteDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplateInfernoSiteSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplateInfernoSiteContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplateInfernoSitePartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplateInfernoSiteAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplateInfernoSiteAnalytics>;
};

export type SitesTemplateInfernoSiteAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars['Boolean']>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars['Boolean']>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars['Boolean']>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars['String']>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars['String']>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars['String']>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplateInfernoSiteAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars['String']>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars['Boolean']>;
  poc_primary_analytics_id?: Maybe<Scalars['String']>;
  poc_secondary_analytics_id?: Maybe<Scalars['String']>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars['Boolean']>;
  parsely_account_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars['String']>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars['Boolean']>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars['String']>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars['String']>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars['String']>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars['String']>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars['Boolean']>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars['String']>;
  /** Physical street address */
  location_address1?: Maybe<Scalars['String']>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars['String']>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars['String']>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars['String']>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars['String']>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars['String']>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<SitesTemplateInfernoSiteContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars['Boolean']>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars['String']>;
  /** Location of public file assistance page if not using the iHeartMedia standard text */
  public_file_assistance_link_override?: Maybe<Scalars['String']>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars['String']>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars['String']>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars['String']>;
  /** Informs default color palette used on the site */
  color_scheme?: Maybe<TagRef>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum 600px on the smallest side */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Choose a font style to use for all text across the site */
  font?: Maybe<TagRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  accent_color?: Maybe<Scalars['String']>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  ihr_embed_player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** E.g. 'Listen Live'. Can be left blank. */
  ihr_embed_player_bar_cta?: Maybe<Scalars['String']>;
  /** iHeart Stream ID */
  ihr_embed_player_bar_stream_id?: Maybe<Scalars['String']>;
  ihr_embed_player_bar_stream_type?: Maybe<Scalars['String']>;
  ihr_embed_player_bar_stream_theme?: Maybe<Scalars['String']>;
  /** Image used at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /** Face color at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_color?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars['String']>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars['String']>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /**
   * Facets used to distribute Content/Leads items to select feeds other than the
   * normal brand/market/genre/format tags already on a site (ex: regional news hub facets)
   */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /** Specify which iHeartRadio playlists are this sites' defaults */
  default_playlists?: Maybe<Array<PlaylistRef>>;
  /**
   * Specify this site's primary personalities by selecting their microsite brands.
   *  Used to populate the personality-focused sections of the homepage
   */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars['String']>;
  /**
   * The country this site is licensed to or originates from. This information is
   * sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars['String']>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars['String']>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplateInfernoSiteGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines what language hard-coded template text is displayed in */
  locale?: Maybe<TagRef>;
  /** @deprecated Replaced by `locale`. */
  language?: Maybe<Scalars['String']>;
  additional_copyright?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars['String']>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars['String']>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars['String']>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars['String']>;
  /** Text of the FCC Application Notice as provided by the Public File team */
  application_text?: Maybe<Scalars['String']>;
  /** Link to the FCC application, as provided by the Public File team */
  application_link?: Maybe<Scalars['String']>;
  /** Location of political file if not hosted on the iHeartMedia system */
  political_link_override?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars['Boolean']>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the addition of contact links in the site header and footer */
  contact_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the addition of the advertise with us links in the site header and footer */
  advertise_switch?: Maybe<Scalars['Boolean']>;
  /**
   * Enables links in the site footer for Public Inspection File and Political file in the footer
   * @deprecated No longer supported.
   */
  show_public_file_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateInfernoSitePartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars['Boolean']>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<CatalogPointerRef>;
  /** Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations */
  ihr_player_playlist?: Maybe<Scalars['Boolean']>;
  /**
   * Specify whether content shown on this site's iHeart.com profile page opens on
   * iHeart.com (checked) or on the station site (unchecked).
   */
  ihr_show_in_iheart_switch?: Maybe<Scalars['Boolean']>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars['Boolean']>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars['Boolean']>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars['String']>;
  /** Authentication provider. SDK-bridge is likely what you want here */
  auth_type?: Maybe<TagRef>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars['Boolean']>;
  /** Campaign ID from Aptivada PromoBuilder for the active keyword contest on this site */
  aptivada_id?: Maybe<Scalars['String']>;
  /** Allows you to verify that you own this domain */
  google_webmaster_verification?: Maybe<Scalars['String']>;
};

export type SitesTemplateInfernoSiteSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars['String']>;
  /**
   * Enables this brand to publish Facebook Instant Articles, requires fan page ID
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'FBIA')` resolver.
   */
  facebook_page_token?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  tiktok_switch?: Maybe<Scalars['Boolean']>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalMicrosite = {
  /** Supply basic information about this microsite's name/description, default content, locale, imagery, and podcast */
  general?: Maybe<SitesTemplateLocalMicrositeGeneral>;
  /** Manage secondary navigation that appearse at the top of every page within this microsite */
  navigation?: Maybe<SitesTemplateLocalMicrositeNavigation>;
  /** Specify a long-form biography and contact information for this microsite */
  contact?: Maybe<SitesTemplateLocalMicrositeContact>;
  /** Connect this microsite with various social networks */
  social?: Maybe<SitesTemplateLocalMicrositeSocial>;
};

export type SitesTemplateLocalMicrositeContact = {
  full_bio?: Maybe<Scalars['String']>;
  /** Dedicated phone number for this show, general station request lines not recommended */
  phone_number?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the show - legally required disclaimer added automatically */
  txt_number?: Maybe<Scalars['String']>;
  /** Public e-mail address, visible to end users - for multi-market shows, use an @iheartmedia.com or @iheartradio.com address */
  email_address?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalMicrositeGeneral = {
  /** The name of the brand, as shown at the top of the page */
  name?: Maybe<Scalars['String']>;
  /**
   * Used for Google and other search engines and appears at the bottom of
   * microsite pages.  Recommended length 275-300 characters
   */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes.  Also can be used for ad targeting */
  keywords?: Maybe<Array<TagRef>>;
  /**
   * Associate this microsite with one or more CORE shows
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'show-keys')` resolver.
   */
  linked_shows?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics to populate this microsite's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /** Specify content topics for distributing content to this site's homepage */
  default_distribution?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /** 2880x570 top of page image - search 'hero' at digitalsupport.iheart.com for best practices and template */
  hero_image?: Maybe<MediaAssetRef>;
  /** Square image at least 600x600px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
  /** Upload a 16x9 image, at least 300px tall */
  sixteen_by_nine?: Maybe<MediaAssetRef>;
  /** Specify the color the hero image fades to on the sides */
  hero_fallback_color?: Maybe<Scalars['String']>;
  /** Enables 3rd party recommendation widgets to appear on this site, if also enabled on parent site */
  recommendations?: Maybe<Scalars['Boolean']>;
  /** Determines whether microsite is visible to end users */
  status?: Maybe<Scalars['String']>;
  /** Outside of these dates, this microsite will not be accessible */
  active_start?: Maybe<DateRef>;
  active_end?: Maybe<DateRef>;
  password_switch?: Maybe<Scalars['Boolean']>;
  /** Contact Digital Support to add/remove password protection */
  password?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalMicrositeNavigation = {
  enable_nav_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateLocalMicrositeSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of your facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/JohnDoe, put "JohnDoe" here - if at youtube.com/channel/JaneSmith, put "channel/JaneSmith" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This microsite's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSite = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplateLocalSiteGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplateLocalSiteAlert>;
  navigation?: Maybe<SitesTemplateLocalSiteNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplateLocalSiteDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplateLocalSiteSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplateLocalSiteContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplateLocalSitePartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplateLocalSiteAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplateLocalSiteAnalytics>;
};

export type SitesTemplateLocalSiteAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars['Boolean']>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars['Boolean']>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars['Boolean']>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars['String']>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars['String']>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars['String']>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplateLocalSiteAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars['String']>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars['Boolean']>;
  poc_primary_analytics_id?: Maybe<Scalars['String']>;
  poc_secondary_analytics_id?: Maybe<Scalars['String']>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars['Boolean']>;
  parsely_account_id?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars['String']>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars['Boolean']>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars['String']>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars['String']>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars['String']>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars['String']>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars['Boolean']>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars['String']>;
  /** Physical street address */
  location_address1?: Maybe<Scalars['String']>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars['String']>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars['String']>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars['String']>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars['String']>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars['String']>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<SitesTemplateLocalSiteContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars['Boolean']>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars['String']>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars['String']>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars['String']>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars['String']>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum size 316x200px */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  link_color?: Maybe<Scalars['String']>;
  /** Background color for the header at the top of the site */
  nav_bg_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_text_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_ihr_link_color?: Maybe<Scalars['String']>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** Image used at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /** Face color at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_color?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars['String']>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars['String']>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /** Specify content topics for distributing content to this site's homepage */
  default_distribution?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /**
   * Specify this site's primary personalities by selecting their microsite brands.
   *  Used to populate the personality-focused sections of the homepage
   */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars['String']>;
  /**
   * The country this site is licensed to or originates from. This information is
   * sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars['String']>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars['String']>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplateLocalSiteGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines whether text that cannot otherwise be customized on this site shows in English or Spanish */
  language?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars['String']>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars['String']>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars['String']>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars['Boolean']>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the addition of social and contact links in the site header and footer */
  connect_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables links in the site footer for Public Inspection File and Political file in the footer */
  show_public_file_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplateLocalSitePartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars['Boolean']>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<CatalogPointerRef>;
  /** Specify the maximum number of tracks that should be shown on the Top Songs page */
  ihr_playlist_max?: Maybe<Scalars['String']>;
  /** Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations */
  ihr_player_playlist?: Maybe<Scalars['Boolean']>;
  /**
   * Specify whether content shown on this site's iHeart.com profile page opens on
   * iHeart.com (checked) or on the station site (unchecked).
   */
  ihr_show_in_iheart_switch?: Maybe<Scalars['Boolean']>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars['Boolean']>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the Total Traffic SigAlert widget on this site */
  total_traffic_switch?: Maybe<Scalars['Boolean']>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars['String']>;
  /** Zoom level on map, 0 = most zoomed in; 3 = most zoomed out */
  traffic_zoom?: Maybe<Scalars['String']>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars['Boolean']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  alexa_pronouncer?: Maybe<Scalars['String']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  google_pronouncer?: Maybe<Scalars['String']>;
};

export type SitesTemplateLocalSiteSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_api_key?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_secret?: Maybe<Scalars['String']>;
  /** Enables this brand to publish Facebook Instant Articles, requires fan page ID */
  facebook_page_token?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  tiktok_switch?: Maybe<Scalars['Boolean']>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars['String']>;
};

export type SitesTemplateOnairEnrichment = {
  /** Supply basic information about this onair entry's branding */
  general?: Maybe<SitesTemplateOnairEnrichmentGeneral>;
};

export type SitesTemplateOnairEnrichmentGeneral = {
  /** The name of the brand, as shown in the onair entry */
  name?: Maybe<Scalars['String']>;
  /** A link to an off-network site that this onair entry should direct to */
  link?: Maybe<Scalars['String']>;
  /** Square image at least 600x600px - image is converted to a circle in some use cases */
  thumbnail_image?: Maybe<MediaAssetRef>;
};

export type SitesTemplatePremiereSite = {
  /** Supply basic information about this site's name/description, default content, locale, and licenses */
  general?: Maybe<SitesTemplatePremiereSiteGeneral>;
  /** Display a breaking news alert at the top of every page on this site */
  alert?: Maybe<SitesTemplatePremiereSiteAlert>;
  navigation?: Maybe<SitesTemplatePremiereSiteNavigation>;
  /** Customize the colors and logos shown on this site */
  design?: Maybe<SitesTemplatePremiereSiteDesign>;
  /** Connect this site with various social networks */
  social?: Maybe<SitesTemplatePremiereSiteSocial>;
  /** Customize this site's contact page, upload EEO annual reports, and specify internal contacts */
  contact?: Maybe<SitesTemplatePremiereSiteContact>;
  /** Enables this site to connect with various 3rd party integrations */
  partners?: Maybe<SitesTemplatePremiereSitePartners>;
  /** Enable monetization integrations with this site */
  ads?: Maybe<SitesTemplatePremiereSiteAds>;
  /** Enable 3rd party tracking integrations */
  analytics?: Maybe<SitesTemplatePremiereSiteAnalytics>;
};

export type SitesTemplatePremiereSiteAds = {
  /** Enables display ads powered by Google Ad Manager */
  dfp_switch?: Maybe<Scalars['Boolean']>;
  /** Enables real-time bidding on unsold display ad inventory */
  header_bidding?: Maybe<Scalars['Boolean']>;
  /** Used to track facebook advertising though the associated Facebook business account */
  facebook_ad_track_pixel_switch?: Maybe<Scalars['Boolean']>;
  /** ID to use with the Facebook tracking pixel */
  facebook_ad_track_pixel_id?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteAlert = {
  /** Message shown to users - more than 90 characters will be truncated with a "Read More" link to see the rest */
  title?: Maybe<Scalars['String']>;
  /** Click through location for the alert - begin with either / - or https:// */
  link?: Maybe<Scalars['String']>;
  /** Selects whether the link opens in the same or a new window */
  target?: Maybe<Scalars['String']>;
  /** Date and time that this alert will first appear on the site */
  start_date?: Maybe<DateRef>;
  /** Date and time that this alert will disappear */
  end_date?: Maybe<DateRef>;
};

export type SitesTemplatePremiereSiteAnalytics = {
  /** Determines which Adobe Analytics tracking mechanism is used */
  adobe_library?: Maybe<Scalars['String']>;
  /** Allows traffic from this site to pass into a local or regional suite in addition to the national suite */
  google_analytics_switch?: Maybe<Scalars['Boolean']>;
  poc_primary_analytics_id?: Maybe<Scalars['String']>;
  poc_secondary_analytics_id?: Maybe<Scalars['String']>;
  /** Records traffic from this site in Parse.ly analytics */
  parsely_switch?: Maybe<Scalars['Boolean']>;
  parsely_account_id?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteContact = {
  /** A short sentence or two inviting users to contact the station - do not use to promote the local advertising contact */
  page_intro?: Maybe<Scalars['String']>;
  /** Enables a block on the contact page with links to each social network */
  show_social_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a block on the page with this station's phone numbers */
  phone_text_numbers_switch?: Maybe<Scalars['Boolean']>;
  /** Public phone number that listeners can call to reach the studio */
  request_phone_number?: Maybe<Scalars['String']>;
  /** Station's front desk phone number */
  business_phone_number?: Maybe<Scalars['String']>;
  /** Total Traffic tipline for this station's market */
  traffic_tip_phone?: Maybe<Scalars['String']>;
  /** Phone number to reach the newsroom for this station/market */
  newsroom_phone?: Maybe<Scalars['String']>;
  /** Shortcode listeners may use to text the station - legally required disclaimer added automatically */
  sms_number?: Maybe<Scalars['String']>;
  /** Enables a block with prize pickup information and a map */
  prize_pickup_directions_switch?: Maybe<Scalars['Boolean']>;
  /** A short sentence or two congratulating winners and welcoming them to pick up their prize */
  prize_pickup_info?: Maybe<Scalars['String']>;
  /** Physical street address */
  location_address1?: Maybe<Scalars['String']>;
  /** Floor or suite number */
  location_address2?: Maybe<Scalars['String']>;
  /** Business office's city, NOT the city of license or market name */
  location_city?: Maybe<Scalars['String']>;
  /** Two letter abbreviation, all caps */
  location_state?: Maybe<Scalars['String']>;
  /** Five or nine digits */
  location_zip?: Maybe<Scalars['String']>;
  /** Example: Open Weekdays 9AM-5PM, closed weekends, major holidays, and usually close early the day before a holiday */
  office_hours?: Maybe<Scalars['String']>;
  /** Displays an e-mail submission form on the contact page */
  email_form_switch?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<SitesTemplatePremiereSiteContactContacts>>;
  /** Enables display of legally-required EEO information on the contact page */
  employment_info_switch?: Maybe<Scalars['Boolean']>;
  /** Upload a PDF of the market's annual EEO report */
  eeo_report_asset?: Maybe<MediaAssetRef>;
  /** If contact page is not linking to the correct jobs page on iheartmedia.jobs, override here */
  employment_link_override?: Maybe<Scalars['String']>;
  /** Location of public file assistance page if not using the iHeartMedia standard text */
  public_file_assistance_link_override?: Maybe<Scalars['String']>;
  /** Specify the Digital PD or coordinator that manages this site. */
  tech_contact?: Maybe<Scalars['String']>;
  /** Specify the on-air PD or SVPP of this station */
  programming_contact?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteContactContacts = {
  /** Allows user to select recipient - also used as subject line when e-mail is sent */
  type?: Maybe<Scalars['String']>;
  /** E-mails of this type are sent to this address. */
  email?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteDesign = {
  /** Theme to use when rendering the site */
  theme?: Maybe<Scalars['String']>;
  /** Upload station's vector logo - EPS, PDF, or AI formats accepted */
  logo_best_res?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG of station's logo, cropped to the actual artwork, minimum size 316x200px */
  station_logo?: Maybe<MediaAssetRef>;
  /** Upload a transparent PNG icon, used as the favicon and in select other areas, minimum size 300x300px */
  logo_favicon?: Maybe<MediaAssetRef>;
  /** Choose a dark color for use as most text links, icons, and buttons. Hover states automatically calculated */
  link_color?: Maybe<Scalars['String']>;
  /** Background color for the header at the top of the site */
  nav_bg_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_text_color?: Maybe<Scalars['String']>;
  /** Choose a contrasting color from the background to ensure legibility */
  nav_ihr_link_color?: Maybe<Scalars['String']>;
  /** Displays the stripe at bottom of site with links to listen live */
  player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** Displays the stripe at bottom with the embedded iHeart mini player */
  ihr_embed_player_bar_switch?: Maybe<Scalars['Boolean']>;
  /** E.g. 'Listen Live'. Can be left blank. */
  ihr_embed_player_bar_cta?: Maybe<Scalars['String']>;
  /** iHeart Stream ID */
  ihr_embed_player_bar_stream_id?: Maybe<Scalars['String']>;
  ihr_embed_player_bar_stream_type?: Maybe<Scalars['String']>;
  ihr_embed_player_bar_stream_theme?: Maybe<Scalars['String']>;
  /** Image used at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_image?: Maybe<MediaAssetRef>;
  /** Face color at top of iHeart.com station profile. Contact Digital Support for assistance */
  ihr_hero_color?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteGeneral = {
  /** Station's name only - no taglines here */
  name?: Maybe<Scalars['String']>;
  /** Station's tagline, including city or region */
  positioner?: Maybe<Scalars['String']>;
  /** Used for Google and other search engines and appears in site footer.  Recommended length 275-300 characters */
  description?: Maybe<Scalars['String']>;
  /** Used for SEO purposes */
  keywords?: Maybe<Array<TagRef>>;
  /** Specify up to 5 content topics for use on the site's homepage */
  default_content?: Maybe<Array<TagRef>>;
  /** Categories used to specify what types of content should display on the site's homepage */
  default_categories?: Maybe<TagRef>;
  /**
   * Facets used to distribute Content/Leads items to select feeds other than the
   * normal brand/market/genre/format tags already on a site (ex: regional news hub facets)
   */
  additional_distributions?: Maybe<Array<TagRef>>;
  /** Specify which iHeartRadio podcasts are hosted by this site's personalities */
  default_podcasts?: Maybe<Array<PodcastRef>>;
  /**
   * Specify this site's primary personalities by selecting their microsite brands.
   *  Used to populate the personality-focused sections of the homepage
   */
  default_shows?: Maybe<Array<SiteRef>>;
  /** Used to show a "friendly" market name throughout various visual components of the site */
  weather_market?: Maybe<Scalars['String']>;
  /** Used to center the traffic map and pull weather data */
  geo_latitude?: Maybe<Scalars['String']>;
  /** Used to center the traffic map and pull weather data */
  geo_longitude?: Maybe<Scalars['String']>;
  /** Specify which time zone this station is in */
  tz_id?: Maybe<Scalars['String']>;
  /**
   * The country this site is licensed to or originates from. This information is
   * sourced from the Slug, Segments & Time Zone screen.
   * @deprecated This data has been moved to the `IndexRecord.country` resolver.
   */
  country?: Maybe<TagRef>;
  /** @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver. */
  broadcast_facilities?: Maybe<Array<SitesTemplatePremiereSiteGeneralBroadcastFacilities>>;
  /**
   * Parent company/owner of this website
   * @deprecated This data has been moved to the `IndexRecord.integration(type: 'broadcast-facilities')` resolver.
   */
  ownership?: Maybe<TagRef>;
  /** Determines whether text that cannot otherwise be customized on this site shows in English or Spanish */
  language?: Maybe<Scalars['String']>;
  additional_copyright?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteGeneralBroadcastFacilities = {
  /** The broadcast frequency as shown on this station's license */
  broadcast_frequency?: Maybe<Scalars['String']>;
  /** The type of broadcast license for this station */
  broadcast_band?: Maybe<Scalars['String']>;
  /** The FCC licensed facility ID of this station */
  facility_id?: Maybe<Scalars['String']>;
  /** The actual licensed call sign - only include "-FM" if actually included on the license */
  broadcast_call_letters?: Maybe<Scalars['String']>;
  /** Location of political file if not hosted on the iHeartMedia system */
  political_link_override?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteNavigation = {
  /** Enables listen live links to the station's iHeartRadio page */
  listen_live_switch?: Maybe<Scalars['Boolean']>;
  /** Enables a dynamic contesting menu with links to current contests and rules */
  contest_nav_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the search field in the site navigation menu */
  search_switch?: Maybe<Scalars['Boolean']>;
  /** Enables iHeartRadio login in the site navigation menu */
  user_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the addition of social and contact links in the site header and footer */
  connect_menu_switch?: Maybe<Scalars['Boolean']>;
  /** Enables links in the site footer for Public Inspection File and Political file in the footer */
  show_public_file_switch?: Maybe<Scalars['Boolean']>;
  menu?: Maybe<Array<SitesMenu>>;
  menu_footer?: Maybe<Array<SitesMenu>>;
};

export type SitesTemplatePremiereSitePartners = {
  /** Enables all iHeartRadio-related functionality on this site if connected via the Streams Tool */
  iheartradio_switch?: Maybe<Scalars['Boolean']>;
  /**
   * Selects which live station is displayed on this site
   * @deprecated This data is now sourced from the Streams Tool.
   */
  ihr_stream?: Maybe<CatalogPointerRef>;
  /** Specify the maximum number of tracks that should be shown on the Top Songs page */
  ihr_playlist_max?: Maybe<Scalars['String']>;
  /** Enables display of recently played songs throughout the site.  Normally disabled for news/talk/sports stations */
  ihr_player_playlist?: Maybe<Scalars['Boolean']>;
  /**
   * Specify whether content shown on this site's iHeart.com profile page opens on
   * iHeart.com (checked) or on the station site (unchecked).
   */
  ihr_show_in_iheart_switch?: Maybe<Scalars['Boolean']>;
  /** Enables integration with Postup for newsletter subscriptions */
  postup_switch?: Maybe<Scalars['Boolean']>;
  /** Overrides the iHeartDaily e-mail format sent to subscribers of this station */
  email_format?: Maybe<TagRef>;
  /** Enables 3rd party recommendation widgets to appear on this site */
  outbrain_switch?: Maybe<Scalars['Boolean']>;
  /** Enables the Total Traffic SigAlert widget on this site */
  total_traffic_switch?: Maybe<Scalars['Boolean']>;
  /** Contact affiliateSupport@sigalert.com to request new ID */
  traffic_market?: Maybe<Scalars['String']>;
  /** Zoom level on map, 0 = most zoomed in; 3 = most zoomed out */
  traffic_zoom?: Maybe<Scalars['String']>;
  /** Enables Aptivada contesting integration with this site */
  aptivada_switch?: Maybe<Scalars['Boolean']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  alexa_pronouncer?: Maybe<Scalars['String']>;
  /** Phonetic pronounciation shown on info page - contact Digital Support for to update pronouncer used on devices */
  google_pronouncer?: Maybe<Scalars['String']>;
};

export type SitesTemplatePremiereSiteSocial = {
  facebook_switch?: Maybe<Scalars['Boolean']>;
  /** Used to generate links to facebook - just the part of the facebook url after facebook.com/ */
  facebook_name?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_fan_page_id?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_api_key?: Maybe<Scalars['String']>;
  /** Required to enable commenting and other plugins */
  facebook_app_secret?: Maybe<Scalars['String']>;
  /** Enables this brand to publish Facebook Instant Articles, requires fan page ID */
  facebook_page_token?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Twitter handle - no @ symbol */
  twitter_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  /** If at youtube.com/KissFM, put "KissFM" here - if at youtube.com/channel/MixFM, put "channel/MixFM" here */
  youtube_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Instagram handle - no @ symbol */
  instagram_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Snapchat username */
  snapchat_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
  /** This site's Pinterest username */
  pinterest_name?: Maybe<Scalars['String']>;
  tiktok_switch?: Maybe<Scalars['Boolean']>;
  /** This site's TikTok username */
  tiktok_name?: Maybe<Scalars['String']>;
};

export type SiteSummary = {
  hero_color?: Maybe<Scalars['String']>;
  hero_image?: Maybe<Scalars['ImageRef']>;
  request_phone_number?: Maybe<Scalars['String']>;
  sms_number?: Maybe<Scalars['String']>;
  facebook_name?: Maybe<Scalars['String']>;
  facebook_switch?: Maybe<Scalars['Boolean']>;
  googleplus_name?: Maybe<Scalars['String']>;
  googleplus_switch?: Maybe<Scalars['Boolean']>;
  twitter_name?: Maybe<Scalars['String']>;
  twitter_switch?: Maybe<Scalars['Boolean']>;
  instagram_name?: Maybe<Scalars['String']>;
  instagram_switch?: Maybe<Scalars['Boolean']>;
  youtube_name?: Maybe<Scalars['String']>;
  youtube_switch?: Maybe<Scalars['Boolean']>;
  snapchat_name?: Maybe<Scalars['String']>;
  snapchat_switch?: Maybe<Scalars['Boolean']>;
  pinterest_name?: Maybe<Scalars['String']>;
  pinterest_switch?: Maybe<Scalars['Boolean']>;
};

export enum SitesVariableSource {
  Config = 'CONFIG',
  Page = 'PAGE'
}

export type SocialMediaLink = {
  link: Scalars['String'];
  name: Scalars['String'];
};

export type SocialRef = {
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  google?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
};

export type Stream = {
  id: Scalars['Int'];
  raw_id: Scalars['String'];
  name: Scalars['String'];
  name_clean?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  esid?: Maybe<Scalars['String']>;
  fcc_facility_id?: Maybe<Scalars['Float']>;
  rds?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  freq?: Maybe<Scalars['String']>;
  freq_clean?: Maybe<Scalars['String']>;
  callLetterAlias: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  callLetterRoyalty?: Maybe<Scalars['String']>;
  band: Scalars['String'];
  callLetters: Scalars['String'];
  streamingPlatform?: Maybe<TagRef>;
  gracenoteEnabled: Scalars['Boolean'];
  streams: StreamRef;
  cume?: Maybe<Scalars['Float']>;
  logo: Scalars['String'];
  siriLogo?: Maybe<Scalars['String']>;
  created: Scalars['Float'];
  modified: Scalars['Float'];
  isActive: Scalars['String'];
  genres: Array<Maybe<GenreRef>>;
  markets: Array<Maybe<MarketRef>>;
  provider: Scalars['String'];
  feeds: FeedRef;
  brand?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  parentStation?: Maybe<Scalars['Float']>;
  pronouncements?: Maybe<Array<Maybe<PronouncementRef>>>;
  sampaPronouncements?: Maybe<Array<PronouncementRef>>;
  social?: Maybe<SocialRef>;
  adswizz?: Maybe<AdswizzRef>;
  adswizz_zones?: Maybe<AdswizzZonesRef>;
  primaryPronouncement?: Maybe<Scalars['String']>;
  ads: StreamAdsRef;
  /** @deprecated Field no longer supported */
  amp: AmpLiveMeta;
};

export type StreamAdsRef = {
  audioAdProvider?: Maybe<TagRef>;
};

export type StreamRef = {
  hls_stream?: Maybe<Scalars['String']>;
  secure_hls_stream?: Maybe<Scalars['String']>;
  pls_stream?: Maybe<Scalars['String']>;
  secure_pls_stream?: Maybe<Scalars['String']>;
  secure_mp3_pls_stream?: Maybe<Scalars['String']>;
  flv_stream?: Maybe<Scalars['String']>;
  abacast_stream?: Maybe<Scalars['String']>;
  secure_rtmp_stream?: Maybe<Scalars['String']>;
  rtmp_stream?: Maybe<Scalars['String']>;
  shoutcast_stream?: Maybe<Scalars['String']>;
  secure_shoutcast_streams?: Maybe<Scalars['String']>;
  pivot_hls_stream?: Maybe<Scalars['String']>;
  stw_stream?: Maybe<Scalars['String']>;
};

export type StreamSiteQuery = {
  brand?: Maybe<Scalars['String']>;
};

export type Subscription = {
  tags: Array<Scalars['String']>;
};

export type SubscriptionInput = {
  tags: Array<Scalars['String']>;
};

export type Tag = {
  /** @deprecated Field no longer supported */
  batch_id?: Maybe<Scalars['Float']>;
  memberName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  ns: Scalars['String'];
  source?: Maybe<TagSource>;
  isNamespace: Scalars['Boolean'];
  member_of?: Maybe<Array<Scalars['String']>>;
  /** Shorthand for display_name property in tag source property. */
  displayName?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  rawSource?: Maybe<Scalars['JSON']>;
};

export type TagRef = {
  id: Scalars['ID'];
  taxo?: Maybe<Tag>;
};

export type TagSource = {
  id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TaxonomyFunctions = {
  topic?: Maybe<Tag>;
  tag?: Maybe<Tag>;
};


export type TaxonomyFunctionsTopicArgs = {
  topic?: Maybe<Scalars['String']>;
};


export type TaxonomyFunctionsTagArgs = {
  tag: Scalars['String'];
};

export type TopArtist = {
  artistId: Scalars['Int'];
  rank: Scalars['Int'];
  count: Scalars['Int'];
  tracks: Array<PlayedTrack>;
  artist?: Maybe<Artist>;
};

export type TopTrack = {
  trackId: Scalars['Int'];
  rank: Scalars['Int'];
  count: Scalars['Int'];
  track: PnpTrack;
};

export type TrackHistory = {
  artistId: Scalars['Int'];
  albumId: Scalars['Int'];
  trackId: Scalars['Int'];
  title: Scalars['String'];
  trackDuration?: Maybe<Scalars['Int']>;
  imagePath?: Maybe<Scalars['String']>;
  explicitLyrics?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  lyricsId?: Maybe<Scalars['Int']>;
  startTime: Scalars['Int'];
  endTime: Scalars['Int'];
  playbackRights?: Maybe<PlaybackRights>;
  status?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  trackDurationInSec?: Maybe<Scalars['Int']>;
  dataSource?: Maybe<Scalars['String']>;
  artist?: Maybe<Artist>;
  artistName: Scalars['String'];
  albumName: Scalars['String'];
};

export type ToolNotificationSearchQueryVariables = Exact<{
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type ToolNotificationSearchQuery = { pubsub: { query?: Maybe<{ items: Array<Pick<PubsubPublishRecord, 'id' | 'payload'>> }> } };

export const ToolNotificationSearchDocument = gql`
    query ToolNotificationSearch($tags: [String!]!) {
        pubsub {
            query(type: "content:tool-notifications", query: { subscription: { tags: $tags } }) {
                items {
                    id
                    payload
                }
            }
        }
    }
`;
export function getSdk(factory: GraphQLRequestFactory) {
    return {
        ToolNotificationSearch: factory<ToolNotificationSearchQueryVariables, ToolNotificationSearchQuery>(
            "ToolNotificationSearch",
            "Query",
            print(ToolNotificationSearchDocument),
        ),
    };
}
export type SDKInterface = ReturnType<typeof getSdk>;
