import { SortTypes } from "../contexts/sort";
import { LocalStorageInstanceTypes } from "../util/localforage";
import { ApiBase } from "./base";

class Sort extends ApiBase<SortTypes> {
    constructor() {
        super(LocalStorageInstanceTypes.sort);
    }
}

export const sortApi = new Sort();
