import { FunctionComponent } from "react";
import { AppsContext, useAppsInitialState } from "./apps";
import { AuthContext, useAuthInitialState } from "./auth";
import { ListContext, useListInitialState } from "./list";
import { SortContext, useSortInitialState } from "./sort";

export const ContextProvider: FunctionComponent = ({ children }) => (
    <AppsContext.Provider value={useAppsInitialState()}>
        <AuthContext.Provider value={useAuthInitialState()}>
            <SortContext.Provider value={useSortInitialState()}>
                <ListContext.Provider value={useListInitialState()}>{children}</ListContext.Provider>
            </SortContext.Provider>
        </AuthContext.Provider>
    </AppsContext.Provider>
);
