// With HKT we could eliminate dependencies on our client project (https://github.com/microsoft/TypeScript/issues/1213)
import { GraphQLRequestFactory } from "@ihr-radioedit/gql-client";
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AddRemoveMembershipsInput = {
  members: Array<Scalars['String']>;
};

export type AddRemoveObserverInput = {
  observer: ObserverInput;
};

/** Mutation */
export type AppInput = {
  appId: Scalars['String'];
  name: Scalars['String'];
  aliases?: Maybe<Array<Scalars['String']>>;
  settings?: Maybe<SettingsConfigInput>;
  defaultRuntimeConfig?: Maybe<RuntimeConfigInput>;
  description?: Maybe<Scalars['String']>;
  gitUri?: Maybe<Scalars['String']>;
  hasUi?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  capabilities?: Maybe<Array<Scalars['String']>>;
};

/** Query */
export type Application = {
  appId: Scalars['String'];
  createdOn: Scalars['Float'];
  createdBy: Scalars['String'];
  secret: Scalars['String'];
  permissions?: Maybe<Array<Scalars['String']>>;
  archived: Scalars['Boolean'];
  name: Scalars['String'];
  security: Security;
  aliases: Array<Scalars['String']>;
  settings: SettingsConfig;
  defaultRuntimeConfig: RuntimeConfig;
  description: Scalars['String'];
  gitUri: Scalars['String'];
  hasUi: Scalars['Boolean'];
  link: Scalars['String'];
  icon: Scalars['String'];
  traffic: TrafficSplit;
  trafficHistory: Array<TrafficSplit>;
  capabilities: Array<Scalars['String']>;
  stats: ApplicationStats;
  /** shell */
  shell?: Maybe<Array<Maybe<KeyValue>>>;
  /** builds */
  builds?: Maybe<Array<Build>>;
  build?: Maybe<Build>;
  jobs?: Maybe<Array<Job>>;
  job?: Maybe<Job>;
  logs?: Maybe<Array<Log>>;
  versionExists?: Maybe<Scalars['Boolean']>;
};


/** Query */
export type ApplicationShellArgs = {
  s?: Maybe<ShellInput>;
};


/** Query */
export type ApplicationBuildsArgs = {
  filter?: Maybe<BuildListInput>;
};


/** Query */
export type ApplicationBuildArgs = {
  buildId: Scalars['String'];
};


/** Query */
export type ApplicationJobsArgs = {
  filter?: Maybe<JobsFilterInput>;
};


/** Query */
export type ApplicationJobArgs = {
  jobId: Scalars['String'];
};


/** Query */
export type ApplicationLogsArgs = {
  filter?: Maybe<LogListInput>;
};


/** Query */
export type ApplicationVersionExistsArgs = {
  version: Scalars['String'];
};

export type ApplicationListInput = {
  hasUi?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
};

export type ApplicationStats = {
  activeBuilds: Scalars['Int'];
  activeInstances: Scalars['Int'];
};

export type AppUpdateActionsInput = {
  setName?: Maybe<SetNameInput>;
  setAliases?: Maybe<SetAliasesInput>;
  setDefaultRuntimeConfig?: Maybe<SetDefaultRuntimeConfigInput>;
  setSettings?: Maybe<SetSettingsInput>;
  setDescription?: Maybe<SetDescriptionInput>;
  setGitUri?: Maybe<SetGitUriInput>;
  setHasUi?: Maybe<SetHasUiInput>;
  setIcon?: Maybe<SetIconInput>;
  setCapabilities?: Maybe<SetCapabilitiesInput>;
  setTraffic?: Maybe<SetTrafficInput>;
  rollbackTraffic?: Maybe<RollbackTrafficInput>;
  addObserver?: Maybe<AddRemoveObserverInput>;
  removeObserver?: Maybe<AddRemoveObserverInput>;
  addMemberships?: Maybe<AddRemoveMembershipsInput>;
  removeMemberships?: Maybe<AddRemoveMembershipsInput>;
};

export type AuditLogEntry = {
  id: Scalars['String'];
  ts: Scalars['Float'];
  who: Scalars['String'];
  what: Scalars['String'];
  success: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
  meta?: Maybe<Array<KeyValue>>;
};

export type AuditLogListInput = {
  appId?: Maybe<Scalars['String']>;
  buildId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  who?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type Build = {
  appId: Scalars['String'];
  buildId: Scalars['String'];
  labels: Array<Scalars['String']>;
  servicePort: Scalars['Int'];
  dockerImage: Scalars['String'];
  serviceDependencies?: Maybe<Array<Scalars['String']>>;
  runtimeConfig: RuntimeConfig;
  archived: Scalars['Boolean'];
  createdBy: Scalars['String'];
  createdOn: Scalars['Float'];
  livenessProbe?: Maybe<Probe>;
  readinessProbe?: Maybe<Probe>;
  security?: Maybe<ResourceSecurity>;
  runtimeStats?: Maybe<RuntimeStats>;
  resourceRecommendations?: Maybe<ResourceRecommendations>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  job?: Maybe<Job>;
  logs?: Maybe<Array<Log>>;
};


export type BuildJobsArgs = {
  filter?: Maybe<JobsFilterInput>;
};


export type BuildJobArgs = {
  jobId: Scalars['String'];
};


export type BuildLogsArgs = {
  filter?: Maybe<LogListInput>;
};

/** mutation */
export type BuildInput = {
  appId: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  servicePort?: Maybe<Scalars['Int']>;
  dockerImage: Scalars['String'];
  serviceDependencies?: Maybe<Array<Scalars['String']>>;
  serviceEnvironment?: Maybe<Array<KeyValueInput>>;
  runtimeConfig?: Maybe<RuntimeConfigInput>;
  livenessProbe?: Maybe<ProbeInput>;
  readinessProbe?: Maybe<ProbeInput>;
};

/** query */
export type BuildListInput = {
  buildId?: Maybe<Scalars['String']>;
  createdSince?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  targetGroup?: Maybe<Scalars['String']>;
};

export type BuildUpdateActionsInput = {
  setRuntimeConfig?: Maybe<SetRuntimeConfigInput>;
};

export type BuildWeight = {
  buildId: Scalars['String'];
  weight: Scalars['Float'];
};

export type CronHistoryFilter = {
  running?: Maybe<Scalars['Boolean']>;
  page?: Maybe<JobsPage>;
};

export type CronJobStatus = {
  /** True if at least one instance of the cron job is active */
  running: Scalars['Boolean'];
  lastRun?: Maybe<Scalars['Float']>;
  /** All instances of the cron job (including active ones) that we have information on, sorted most recent to least. */
  instances: Array<JobStatus>;
};


export type CronJobStatusInstancesArgs = {
  filter?: Maybe<CronHistoryFilter>;
};


export type DockerContainer = {
  container_id: Scalars['String'];
};

export type DockerHost = {
  dockerUri: Scalars['String'];
  dockerRegistry: Scalars['String'];
  cert?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type ExecAction = {
  command: Array<Scalars['String']>;
};

export type ExecActionInput = {
  command: Array<Scalars['String']>;
};

export type HttpGetAction = {
  host: Scalars['String'];
  httpHeaders?: Maybe<Array<HttpHeader>>;
  path: Scalars['String'];
  port?: Maybe<Scalars['Int']>;
  scheme?: Maybe<Scalars['String']>;
};

export type HttpGetActionInput = {
  host: Scalars['String'];
  httpHeaders?: Maybe<Array<HttpHeaderInput>>;
  path: Scalars['String'];
  port?: Maybe<Scalars['Int']>;
  scheme?: Maybe<Scalars['String']>;
};

export type HttpHeader = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type HttpHeaderInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/**
 * input SetReadinessProbeInput {
 *     readinessProbe: ProbeInput
 * }
 */
export type Infrastructure = {
  targetGroups?: Maybe<Array<TargetGroup>>;
  targetGroup?: Maybe<TargetGroup>;
  dockerHost?: Maybe<DockerHost>;
};


/**
 * input SetReadinessProbeInput {
 *     readinessProbe: ProbeInput
 * }
 */
export type InfrastructureTargetGroupArgs = {
  name: Scalars['String'];
};

export type Job = {
  appId: Scalars['String'];
  jobId: Scalars['String'];
  buildId?: Maybe<Scalars['String']>;
  labels: Array<Scalars['String']>;
  schedule: Scalars['String'];
  command: Scalars['String'];
  timeout: Scalars['Int'];
  retries: Scalars['Int'];
  concurrencyPolicy: JobConcurrencyPolicy;
  archived: Scalars['Boolean'];
  paused: Scalars['Boolean'];
  createdBy: Scalars['String'];
  createdOn: Scalars['Float'];
  updatedOn: Scalars['Float'];
  type: JobType;
  status?: Maybe<JobStatus>;
  cronStatus?: Maybe<CronJobStatus>;
  logs?: Maybe<Array<Log>>;
};


export type JobLogsArgs = {
  filter?: Maybe<LogListInput>;
};

export enum JobConcurrencyPolicy {
  Allow = 'Allow',
  Forbid = 'Forbid',
  Replace = 'Replace'
}

export type JobExecuteInput = {
  appId: Scalars['String'];
  buildId: Scalars['String'];
  command: Scalars['String'];
  timeout: Scalars['Int'];
  retries?: Maybe<Scalars['Int']>;
  labels?: Maybe<Array<Scalars['String']>>;
};

export type JobResponse = {
  name: Scalars['String'];
  active: Scalars['Int'];
  retries: Scalars['Int'];
  commands?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  restartPolicy: Scalars['String'];
  schedule: Scalars['String'];
  created: Scalars['String'];
  lastRun: Scalars['String'];
};

/** mutation */
export type JobScheduleInput = {
  appId: Scalars['String'];
  buildId: Scalars['String'];
  schedule: Scalars['String'];
  command: Scalars['String'];
  timeout: Scalars['Int'];
  retries?: Maybe<Scalars['Int']>;
  concurrencyPolicy?: Maybe<JobConcurrencyPolicy>;
  paused?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Scalars['String']>>;
};

export type JobsFilterInput = {
  archived?: Maybe<Scalars['Boolean']>;
  paused?: Maybe<Scalars['Boolean']>;
  jobId?: Maybe<Scalars['String']>;
  type?: Maybe<JobType>;
  label?: Maybe<Scalars['String']>;
  running?: Maybe<Scalars['Boolean']>;
  page?: Maybe<JobsPage>;
};

/** query */
export type JobsPage = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type JobStatus = {
  running: Scalars['Boolean'];
  /** The name of the job in kubernetes. Use this name if you need to cancel a job execution. */
  kubeName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Float']>;
  /** True if we know the job is no longer running, regardless of presence or lack of success/fail info. */
  exited?: Maybe<Scalars['Boolean']>;
  /** Null if the job is still running, or if it failed to complete */
  finishDate?: Maybe<Scalars['Float']>;
  /** Null if the job is still running */
  succeeded?: Maybe<Scalars['Boolean']>;
  /** Non-null if the job has completed and succeeded=false */
  kubeErrorText?: Maybe<Scalars['String']>;
  /** A succinct error code from kubernetes -- accompanies kubeErrorText */
  kubeErrorReason?: Maybe<Scalars['String']>;
  /** True if the job was manually stopped by a user */
  canceled?: Maybe<Scalars['Boolean']>;
};

export enum JobType {
  Single = 'Single',
  Cron = 'Cron'
}

export type JobUpdateActionsInput = {
  setPaused?: Maybe<SetPausedInput>;
  setLabels?: Maybe<SetLabelsInput>;
};


export type KeyValue = {
  k: Scalars['String'];
  v: Scalars['String'];
};

export type KeyValueInput = {
  k: Scalars['String'];
  v: Scalars['String'];
};

export type KubernetesInfo = {
  container_name: Scalars['String'];
  namespace_name: Scalars['String'];
  pod_name: Scalars['String'];
  container_image?: Maybe<Scalars['String']>;
  container_image_id?: Maybe<Scalars['String']>;
  pod_id: Scalars['String'];
  labels: Array<KeyValue>;
  host: Scalars['String'];
  master_url: Scalars['String'];
  namespace_id: Scalars['String'];
};

export type Log = {
  log: Scalars['String'];
  stream: Scalars['String'];
  docker: DockerContainer;
  kubernetes: KubernetesInfo;
  time: Scalars['Date'];
};

export type LogListInput = {
  limit?: Maybe<Scalars['Int']>;
  since?: Maybe<Scalars['String']>;
};

/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type Mutation = {
  createApp?: Maybe<Application>;
  updateApp?: Maybe<Application>;
  archiveApp?: Maybe<Application>;
  createBuild?: Maybe<Build>;
  updateBuild?: Maybe<Build>;
  archiveBuild?: Maybe<Build>;
  executeJob?: Maybe<Job>;
  scheduleJob?: Maybe<Job>;
  updateJob?: Maybe<Job>;
  archiveJob?: Maybe<Job>;
  cancelJob?: Maybe<Job>;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationCreateAppArgs = {
  app: AppInput;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationUpdateAppArgs = {
  appId: Scalars['String'];
  appUpdates: Array<AppUpdateActionsInput>;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationArchiveAppArgs = {
  appId: Scalars['String'];
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationCreateBuildArgs = {
  build: BuildInput;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationUpdateBuildArgs = {
  appId: Scalars['String'];
  buildId: Scalars['String'];
  buildUpdates: Array<BuildUpdateActionsInput>;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationArchiveBuildArgs = {
  appId: Scalars['String'];
  buildId: Scalars['String'];
  force?: Maybe<Scalars['Boolean']>;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationExecuteJobArgs = {
  job: JobExecuteInput;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationScheduleJobArgs = {
  job: JobScheduleInput;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationUpdateJobArgs = {
  appId: Scalars['String'];
  jobId: Scalars['String'];
  jobUpdates: Array<JobUpdateActionsInput>;
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationArchiveJobArgs = {
  appId: Scalars['String'];
  jobId: Scalars['String'];
};


/** Mutation design inspiration from https://techblog.commercetools.com/modeling-graphql-mutations-52d4369f73b1 */
export type MutationCancelJobArgs = {
  appId: Scalars['String'];
  jobId: Scalars['String'];
  instanceId?: Maybe<Scalars['String']>;
};

export type ObserverInput = {
  member: Scalars['String'];
  capability?: Maybe<Scalars['String']>;
};

export type Probe = {
  exec?: Maybe<ExecAction>;
  failureThreshold?: Maybe<Scalars['Int']>;
  httpGet?: Maybe<HttpGetAction>;
  initialDelaySeconds?: Maybe<Scalars['Int']>;
  periodSeconds?: Maybe<Scalars['Int']>;
  successThreshold?: Maybe<Scalars['Int']>;
  tcpSocket?: Maybe<TcpSocketAction>;
  timeoutSeconds?: Maybe<Scalars['Int']>;
};

export type ProbeInput = {
  exec?: Maybe<ExecActionInput>;
  failureThreshold?: Maybe<Scalars['Int']>;
  httpGet?: Maybe<HttpGetActionInput>;
  initialDelaySeconds?: Maybe<Scalars['Int']>;
  periodSeconds?: Maybe<Scalars['Int']>;
  successThreshold?: Maybe<Scalars['Int']>;
  tcpSocket?: Maybe<TcpSocketActionInput>;
  timeoutSeconds?: Maybe<Scalars['Int']>;
};

export type Query = {
  applications?: Maybe<Array<Application>>;
  application?: Maybe<Application>;
  builds: Array<Build>;
  infrastructure?: Maybe<Infrastructure>;
  auditLog?: Maybe<Array<AuditLogEntry>>;
  capabilities?: Maybe<Array<Scalars['String']>>;
};


export type QueryApplicationsArgs = {
  filter?: Maybe<ApplicationListInput>;
};


export type QueryApplicationArgs = {
  appId: Scalars['String'];
};


export type QueryBuildsArgs = {
  filter?: Maybe<BuildListInput>;
};


export type QueryAuditLogArgs = {
  filter?: Maybe<AuditLogListInput>;
};


export type QueryCapabilitiesArgs = {
  appIds?: Maybe<Array<Scalars['String']>>;
};

export type ResourceRecommendations = {
  cpuRequested: Scalars['Float'];
  memoryRequested: Scalars['Int'];
};

export type ResourceSecurity = {
  memberOf: Array<Scalars['String']>;
};

export type RollbackTrafficInput = {
  trafficId: Scalars['String'];
};

export type RuntimeConfig = {
  /** KUBE */
  active: Scalars['Boolean'];
  minReplicas: Scalars['Int'];
  maxReplicas: Scalars['Int'];
  cpuThreshold: Scalars['Int'];
  memoryRequested: Scalars['Int'];
  cpuRequested: Scalars['Float'];
  memoryLimit: Scalars['Int'];
  cpuLimit: Scalars['Float'];
  /** PROXY */
  handleCors: Scalars['Boolean'];
  checkAuth: Scalars['Boolean'];
  authExemptions: Array<Scalars['String']>;
  aliases: Array<Scalars['String']>;
  forceHttps: Scalars['Boolean'];
  public: Scalars['Boolean'];
  /** DOCKER */
  command: Array<Scalars['String']>;
  targetGroup: Scalars['String'];
  environment: Array<KeyValue>;
};

export type RuntimeConfigInput = {
  /** KUBE */
  active?: Maybe<Scalars['Boolean']>;
  minReplicas?: Maybe<Scalars['Int']>;
  maxReplicas?: Maybe<Scalars['Int']>;
  cpuThreshold?: Maybe<Scalars['Int']>;
  memoryRequested?: Maybe<Scalars['Int']>;
  cpuRequested?: Maybe<Scalars['Float']>;
  memoryLimit?: Maybe<Scalars['Int']>;
  cpuLimit?: Maybe<Scalars['Float']>;
  /** PROXY */
  handleCors?: Maybe<Scalars['Boolean']>;
  checkAuth?: Maybe<Scalars['Boolean']>;
  authExemptions?: Maybe<Array<Scalars['String']>>;
  aliases?: Maybe<Array<Scalars['String']>>;
  forceHttps?: Maybe<Scalars['Boolean']>;
  public?: Maybe<Scalars['Boolean']>;
  /** DOCKER */
  command?: Maybe<Array<Scalars['String']>>;
  targetGroup?: Maybe<Scalars['String']>;
  environment?: Maybe<Array<KeyValueInput>>;
};

export type RuntimeStats = {
  instances: Scalars['Int'];
  targetInstances: Scalars['Int'];
  uptime: Scalars['Int'];
};

export type Security = {
  observerOf: Array<SecurityObserver>;
  memberOf: Array<Scalars['String']>;
};

export type SecurityGrant = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SecurityObserver = {
  member: Scalars['String'];
  capability?: Maybe<Scalars['String']>;
  grantedBy?: Maybe<SecurityGrant>;
};

export type SetAliasesInput = {
  aliases: Array<Scalars['String']>;
};

export type SetCapabilitiesInput = {
  capabilities: Array<Scalars['String']>;
};

export type SetDefaultRuntimeConfigInput = {
  defaultRuntimeConfig: RuntimeConfigInput;
};

export type SetDescriptionInput = {
  description: Scalars['String'];
};

export type SetGitUriInput = {
  gitUri: Scalars['String'];
};

export type SetHasUiInput = {
  hasUi: Scalars['Boolean'];
};

export type SetIconInput = {
  icon: Scalars['String'];
};

export type SetLabelsInput = {
  labels: Array<Scalars['String']>;
};

export type SetNameInput = {
  name: Scalars['String'];
};

export type SetPausedInput = {
  paused: Scalars['Boolean'];
};

export type SetRuntimeConfigInput = {
  runtimeConfig: RuntimeConfigInput;
};

export type SetSettingsInput = {
  settings: SettingsConfigInput;
};

export type SettingsConfig = {
  maxActiveBuilds: Scalars['Int'];
  maxActiveJobs: Scalars['Int'];
};

export type SettingsConfigInput = {
  maxActiveBuilds?: Maybe<Scalars['Int']>;
  maxActiveJobs?: Maybe<Scalars['Int']>;
};

export type SetTrafficInput = {
  traffic: TrafficInput;
};

export type ShellInput = {
  serviceDependencies?: Maybe<Array<Scalars['String']>>;
  environment?: Maybe<Array<KeyValueInput>>;
};

export type Subscription = {
  logs?: Maybe<Array<Log>>;
};


export type SubscriptionLogsArgs = {
  appId: Scalars['String'];
  buildId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  filter?: Maybe<LogListInput>;
};

export type TargetGroup = {
  name: Scalars['String'];
  count: Scalars['Int'];
  machineType: Scalars['String'];
  podCount: Scalars['Int'];
};

export type TcpSocketAction = {
  host: Scalars['String'];
  port: Scalars['Int'];
};

export type TcpSocketActionInput = {
  host: Scalars['String'];
  port: Scalars['Int'];
};

export type TrafficInput = {
  splitBy: TrafficSplitBy;
  weights: Array<TrafficSplitWeightInput>;
};

export type TrafficSplit = {
  trafficId: Scalars['String'];
  splitBy: TrafficSplitBy;
  weights: Array<BuildWeight>;
};

export enum TrafficSplitBy {
  None = 'NONE',
  Random = 'RANDOM',
  HashIp = 'HASH_IP',
  StickyCookie = 'STICKY_COOKIE'
}

export type TrafficSplitWeightInput = {
  buildId: Scalars['String'];
  weight: Scalars['Float'];
};

export type AppSummaryFragment = Pick<Application, 'appId' | 'name' | 'description' | 'createdOn' | 'createdBy' | 'aliases' | 'hasUi' | 'icon' | 'link'>;

export type AppListQueryVariables = Exact<{
  filter?: Maybe<ApplicationListInput>;
}>;


export type AppListQuery = { applications?: Maybe<Array<AppSummaryFragment>> };

export const AppSummaryFragmentDoc = gql`
    fragment AppSummary on Application {
        appId
        name
        description
        createdOn
        createdBy
        aliases
        hasUi
        icon
        link
    }
`;
export const AppListDocument = gql`
    query AppList($filter: ApplicationListInput) {
        applications(filter: $filter) {
            ...AppSummary
        }
    }
    ${AppSummaryFragmentDoc}
`;
export function getSdk(factory: GraphQLRequestFactory) {
    return {
        AppList: factory<AppListQueryVariables, AppListQuery>("AppList", "Query", print(AppListDocument)),
    };
}
export type SDKInterface = ReturnType<typeof getSdk>;
