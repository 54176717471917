import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { listApi } from "../api/list";

export enum ListModes {
    all = "all",
}

export const ListContext = createContext<ReturnType<typeof useListInitialState>>({} as any);

export const useListInitialState = () => {
    const [listMode, setListMode] = useState<ListModes>(ListModes.all);
    useEffect(() => {
        listApi.load().then((mode) => mode && setListMode(mode));
    }, []);
    useEffect(() => {
        if (listMode) {
            listApi.set(listMode);
        }
    }, [listMode]);

    const showAll = useCallback(() => {
        setListMode(ListModes.all);
    }, [setListMode]);

    return { listMode, showAll };
};

export const useListContext = () => useContext(ListContext);
