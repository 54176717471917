import { AppSummaryFragment } from "../graphql/generated/config-graphql.g";
import { configSdk } from "../graphql/sdk";
import { LocalStorageInstanceTypes } from "../util/localforage";
import { ApiBase } from "./base";

export type PluckPromise<T> = T extends PromiseLike<infer U> ? U : T;
export type PluckArray<T> = T extends Array<infer U> ? U : T;
export type Apps = PluckPromise<ReturnType<typeof getApps>>;
export type App = PluckArray<Apps>;

// This needs to be defined outside so its type can be pulled.
function getApps(): Promise<AppSummaryFragment[] | null> {
    return configSdk.AppList.queryAsPromise({
        filter: {
            active: true,
            hasUi: true,
        },
    })
        .then((apps) => apps.data?.applications ?? null)
        .catch((err) => {
            console.error(err);
            return null;
        });
}

class AppsApi extends ApiBase<Apps> {
    constructor() {
        super(LocalStorageInstanceTypes.apps);
    }

    getApps = getApps;
}

export const appsApi = new AppsApi();
