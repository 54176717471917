import { ListModes } from "../contexts/list";
import { LocalStorageInstanceTypes } from "../util/localforage";
import { ApiBase } from "./base";

class List extends ApiBase<ListModes> {
    constructor() {
        super(LocalStorageInstanceTypes.list);
    }
}

export const listApi = new List();
