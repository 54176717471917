import React from "react";
import { Input } from "antd";
import { useCallback } from "react";
import { useSortContext } from "../../contexts/sort";

export const Filters = () => {
    const { setNameSearch } = useSortContext();

    const updateTextFilter = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNameSearch(event.target.value);
        },
        [setNameSearch],
    );

    return (
        <div className="filters">
            <Input.Search className="search" allowClear enterButton onChange={updateTextFilter} />
        </div>
    );
};
