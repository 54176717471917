import { ReconciliationOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Link, useRouteMatch } from "react-router-dom";

export const NavMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
    const onHomePage = useRouteMatch("/");

    return (
        <Menu
            theme="dark"
            mode="inline"
            inlineCollapsed={isCollapsed}
            selectedKeys={onHomePage?.isExact ? ["my-apps"] : []}
        >
            <Menu.Item key="my-apps" title="My Apps">
                <Link to="/">
                    <ReconciliationOutlined />
                    {!isCollapsed ? " My Apps" : ""}
                </Link>
            </Menu.Item>
        </Menu>
    );
};
