import { environ } from "@ihr-radioedit/sdk-utils";
import type { PubsubPublishRecord } from "../graphql/generated/webapi-graphql.g";
import { webapiSdk } from "../graphql/sdk";
import { LocalStorageInstanceTypes } from "../util/localforage";
import { ApiBase } from "./base";

export type NormalizedNotification = {
    description: string;
    title: string;
    link: string;
    id: string;
};

function NormalizeNotification(item: Pick<PubsubPublishRecord, "id" | "payload">): NormalizedNotification | null {
    if (item.payload?.summary?.description && item.payload?.summary?.title && item.id) {
        return {
            description: item.payload.summary.description,
            title: item.payload.summary.title,
            link: item.payload?.fields?.link?.value,
            id: item.id,
        };
    }
    return null;
}

class Notifications extends ApiBase<Set<string>> {
    constructor() {
        super(LocalStorageInstanceTypes.notifications);

        this.load().then((dismissedIds) => {
            if (!dismissedIds) {
                this.set(new Set());
            }
        });
    }

    loadNotifications() {
        return Promise.all([
            webapiSdk.ToolNotificationSearch.queryAsPromise({ tags: [`apps/${environ.application?.id}`] }),
            this.load(),
        ])
            .then(
                ([data, dismissedIds]) =>
                    data.data?.pubsub.query?.items
                        .map(NormalizeNotification)
                        .filter((item): item is NormalizedNotification => !!item && !dismissedIds?.has(item.id)) ?? [],
            )
            .catch((err) => {
                console.error("Error loading notifications: ", err);
                return [];
            });
    }

    async dismiss(notifications: NormalizedNotification[]) {
        const dismissedIds = (await this.load()) || new Set();
        for (const notification of notifications) {
            dismissedIds.add(notification.id);
        }
        this.set(dismissedIds);
    }
}

export const notificationsApi = new Notifications();
