import { useMemo } from "react";
import { useAppsContext } from "../../contexts/apps";
import { useSortContext } from "../../contexts/sort";
import { AppItem } from "./AppItem.component";
import "./AppList.style.scss";
import classNames from "classnames";

export const AppList = () => {
    const { nameSearch } = useSortContext();
    const { apps } = useAppsContext();

    const filteredApps = useMemo(
        () =>
            apps
                ?.filter((app) => (nameSearch ? app.name.toLowerCase().includes(nameSearch.toLowerCase()) : true))
                .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
        [apps, nameSearch],
    );

    let result: JSX.Element | JSX.Element[];

    if (filteredApps.length > 0) {
        result = filteredApps.map((app) => <AppItem key={app.appId} app={app} />);
    } else if (nameSearch) {
        result = <div>
            <h2>No Results</h2>
            <p>Try modifying your search.</p>
        </div>;
    } else {
        result = <div>
            <h2>No Applications</h2>
            <p>You have not been assigned any applications.</p>
            <p>Please contact <a href={"mailto:digitalsupport@iheartmedia.com"}>Digital Support</a> for assistance.</p>
        </div>;
    }

    return (
        <div className="apps-list-container" style={{ display: filteredApps.length ? "block" : "flex" }}>
            <div className={classNames({
                "apps-list": true,
                "empty": filteredApps.length === 0,
            })}>
                {result}
            </div>
        </div>
    );
};
